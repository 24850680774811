import React, { useEffect, useRef } from 'react';
import { Form, Checkbox as SemanticCheckbox } from 'semantic-ui-react';
import { useField } from '@unform/core';

const Checkbox = ({ name, ...rest }) => {
  const inputRef = useRef(null);
  const { fieldName, defaultValue = false, registerField, error } = useField(name);

  useEffect(() => {
    const config = {
      name: fieldName,
      ref: inputRef.current,
      path: 'state.checked'
    };

    registerField(config);
  }, [fieldName, registerField]);

  return (
    <Form.Field error={!!error}>
      <SemanticCheckbox
        ref={inputRef}
        defaultChecked={defaultValue}
        {...rest}
      />
    </Form.Field>
  );
};

export default Checkbox;
