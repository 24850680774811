import React from 'react';
import { useTranslation } from 'react-i18next';
import { Segment, Header, Button } from 'semantic-ui-react';

import './styles.css';

const Blocks = ({ children, building, showBlockModal }) => {
  const { t } = useTranslation();

  return building.blocks.map(block => (
    <Segment.Group key={block.id}>
      <Segment className='block'>
        <Header as='h5' className='block-header'>
          {t(`GLOBAL.LABELS.${building.blockLabel}`)} {block.name}
        </Header>
        <Button
          type='button'
          className='block-action-button'
          icon='pencil'
          onClick={() => showBlockModal(block)}
        />
      </Segment>
      {children(block)}
    </Segment.Group>
  ));
};

export default Blocks;
