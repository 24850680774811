import React from 'react';
import { CompanyContent } from '../company';
import { GeneralContent } from '../general';
import { ConfigurationContent } from '../configuration';
import { StructureContent } from '../structure';
import { PlanContent } from '../plan';
import { ConfirmationContent } from '../confirmation';
import { ProgressContent } from '../progress';

const Contents = ({ step, building, changeStep, setBuilding, companies }) => {
  const prev = () => changeStep(step - 1);
  const next = buildingData => {
    setBuilding({ ...building, ...buildingData });
    changeStep(step + 1);
  };

  return (
    <>
      <CompanyContent
        active={step === 0}
        building={building}
        companies={companies}
        next={next}
      />
      <GeneralContent
        active={step === 1}
        building={building}
        prev={prev}
        next={next}
      />
      <ConfigurationContent
        active={step === 2}
        building={building}
        prev={prev}
        next={next}
      />
      <StructureContent
        active={step === 3}
        building={building}
        setBuilding={setBuilding}
        prev={prev}
        next={next}
      />
      <PlanContent
        active={step === 4}
        building={building}
        setBuilding={setBuilding}
        prev={prev}
        next={next}
      />
      <ConfirmationContent
        active={step === 5}
        building={building}
        companies={companies}
        prev={prev}
        next={next}
      />
      <ProgressContent
        active={step === 6}
        building={building}
        prev={prev}
      />
    </>
  );
};

export default Contents;
