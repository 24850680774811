import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Step, Icon, Button } from 'semantic-ui-react';
import { Form } from '../../../../../components';
import Blocks from '../blocks';
import Floors from '../floors';
import Units from '../units';
import UnitModal from '../unit-modal';
import BlockModal from '../block-modal';
import RelatedActions from '../related-actions';

const StructureStep = ({ active, completed }) => {
  const { t } = useTranslation();

  return (
    <Step active={active} completed={completed}>
      <Icon name='block layout' />
      <Step.Content>
        <Step.Title>{t('BUILDINGS.NEW.STRUCTURE_STEP.TITLE')}</Step.Title>
        <Step.Description>
          {t('BUILDINGS.NEW.STRUCTURE_STEP.DESCRIPTION')}
        </Step.Description>
      </Step.Content>
    </Step>
  );
};

const StructureContent = ({ active, building, setBuilding, prev, next }) => {
  const { t } = useTranslation();
  const [unitModalOpened, setUnitModalOpened] = useState(false);
  const [targetUnit, setTargetUnit] = useState({});
  const [blockModalOpened, setBlockModalOpened] = useState(false);
  const [targetBlock, setTargetBlock] = useState({});
  const removeUnit = unit => {
    const units = building.units.filter(({ id }) => id !== unit.id);

    setBuilding({ ...building, units });
  };
  const showUnitModal = (unit = {}) => {
    setUnitModalOpened(true);
    setTargetUnit(unit);
  };
  const showBlockModal = (block = {}) => {
    setBlockModalOpened(true);
    setTargetBlock(block);
  };
  const saveUnit = changedUnit => {
    if(changedUnit.id) {
      const units = building.units.map(unit => unit.id === changedUnit.id
        ? changedUnit
        : unit);

      setUnitModalOpened(false);
      setTargetUnit({});

      return setBuilding({ ...building, units });
    }

    const id = Math.random().toString(36).substring(2) + Date.now().toString(36);
    const units = [...building.units, { ...changedUnit, id }];
    
    setUnitModalOpened(false);
    setTargetUnit({});
    setBuilding({ ...building, units });
  };
  const saveBlock = changedBlock => {
    const blocks = building.blocks.map(block => block.id === changedBlock.id
      ? changedBlock
      : block);

    setBlockModalOpened(false);
    setTargetBlock({});
    setBuilding({ ...building, blocks });
  };

  return active && (
    <>
      <Form initialData={building} onSubmit={next}>
        <Blocks building={building} showBlockModal={showBlockModal}>
          {block => building.orientation === 'VERTICAL' ? (
            <Floors building={building} block={block}>
              {floor => (
                <>
                  <Units
                    building={building}
                    related={floor}
                    relatedAttribute='floor'
                    removeUnit={removeUnit}
                    showUnitModal={showUnitModal}
                  />
                  <RelatedActions
                    showUnitModal={() => showUnitModal({ floor: floor.id })}
                  />
                </>
              )}
            </Floors>
          ) : (
            <>
              <Units
                building={building}
                related={block}
                relatedAttribute='block'
                removeUnit={removeUnit}
                showUnitModal={showUnitModal}
              />
              <RelatedActions showUnitModal={() => showUnitModal({ block: block.id })} />
            </>
          )}
        </Blocks>
        <Button primary onClick={prev}>{t('BUILDINGS.NEW.PREV')}</Button>
        <Button primary type='submit'>{t('BUILDINGS.NEW.NEXT')}</Button>
      </Form>
      <UnitModal
        unit={targetUnit}
        save={saveUnit}
        opened={unitModalOpened}
        onClose={() => setUnitModalOpened(false)}
      />
      <BlockModal
        block={targetBlock}
        save={saveBlock}
        opened={blockModalOpened}
        onClose={() => setBlockModalOpened(false)}
      />
    </>
  );
};

export { StructureStep, StructureContent };
