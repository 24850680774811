import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'semantic-ui-react';
import { Form, Input } from '../../../../../components';

const schema = Yup.object().shape({
  identifier: Yup.string().required()
});

const UnitModal = ({ unit, save, opened, onClose }) => {
  const { t } = useTranslation();
  const title = unit.id ? 'EDIT_TITLE' : 'NEW_TITLE';

  return (
    <Modal size='mini' open={opened} onClose={onClose}>
      <Modal.Header>{t(`BUILDINGS.NEW.STRUCTURE_STEP.UNIT_MODAL.${title}`)}</Modal.Header>
      <Modal.Content>
        <Form id='unit-form' initialData={unit} onSubmit={save} schema={schema}>
          <Input
            name='identifier'
            label={t('BUILDINGS.NEW.STRUCTURE_STEP.UNIT_MODAL.IDENTIFIER.LABEL')}
            placeholder={t('BUILDINGS.NEW.STRUCTURE_STEP.UNIT_MODAL.IDENTIFIER.PLACEHOLDER')}
          />
          <Input hidden name='id' />
          <Input hidden name='block' />
          <Input hidden name='floor' />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={onClose}>
          {t('BUILDINGS.NEW.STRUCTURE_STEP.UNIT_MODAL.CANCEL')}
        </Button>
        <Button
          positive
          form='unit-form'
          icon='checkmark'
          labelPosition='right'
          content={t('BUILDINGS.NEW.STRUCTURE_STEP.UNIT_MODAL.SAVE')}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default UnitModal;
