import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Container, Segment, Button } from 'semantic-ui-react';
import { Form, Input } from '../../components';
import axios from '../../config/axios';

import logo from '../../assets/images/logo.png';
import './styles.css';

const schema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().required()
});

const Login = ({ setAuth }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const login = async ({ email, password }) => {
    try {
      const data = { type: 'local', email, password };
      //const result = await axios.post('/auth/local', data); 
      const result = await axios.post('/v3/admin/authentication', data); 
      const token = result.data.token;

      axios.defaults.headers.common['Authorization'] = token;
      localStorage.setItem('token', token);
      setAuth({ authenticated: true, token });
      history.push('/');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Container className='login page'>
      <Segment className='login-form'>
        <div className='logo'>
          <img src={logo} alt='logo' />
        </div>
        <Form onSubmit={login} schema={schema}>
          <Input
            name='email'
            label={t('LOGIN.EMAIL.LABEL')}
            placeholder={t('LOGIN.EMAIL.PLACEHOLDER')}
          />
          <Input
            type='password'
            name='password'
            label={t('LOGIN.PASSWORD.LABEL')}
            placeholder={t('LOGIN.PASSWORD.PLACEHOLDER')}
          />
          <Button primary fluid type='submit'>{t('LOGIN.BUTTON')}</Button>
        </Form>
      </Segment>
    </Container>
  );
}

export default Login;
