import React, { useEffect, useRef } from 'react';
import { Form, Dropdown as SemanticDropdown } from 'semantic-ui-react';
import { useField } from '@unform/core';

const Dropdown = ({ name, label, ...rest }) => {
  const inputRef = useRef(null);
  const { fieldName, defaultValue = '', registerField, error } = useField(name);

  useEffect(() => {
    const config = {
      name: fieldName,
      ref: inputRef.current,
      path: 'state.value'
    };

    registerField(config);
  }, [fieldName, registerField]);

  return (
    <Form.Field error={!!error}>
      <label>{label}</label>
      <SemanticDropdown
        ref={inputRef}
        defaultValue={defaultValue}
        error={!!error}
        {...rest}
      />
    </Form.Field>
  );
};

export default Dropdown;
