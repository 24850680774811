import React from 'react';
import { Step } from 'semantic-ui-react';
import { CompanyStep } from '../company';
import { GeneralStep } from '../general';
import { ConfigurationStep } from '../configuration';
import { StructureStep } from '../structure';
import { PlanStep } from '../plan';
import { ConfirmationStep } from '../confirmation';
import { ProgressStep } from '../progress';

const Steps = ({ step }) => (
  <Step.Group vertical>
    <CompanyStep active={step === 0} completed={step > 0} />
    <GeneralStep active={step === 1} completed={step > 1} />
    <ConfigurationStep active={step === 2} completed={step > 2} />
    <StructureStep active={step === 3} completed={step > 3} />
    <PlanStep active={step === 4} completed={step > 4} />
    <ConfirmationStep active={step === 5} completed={step > 5} />
    <ProgressStep active={step === 6} />
  </Step.Group>
);

export default Steps;
