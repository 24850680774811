import React from "react";
import { Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";

const GoBack = ({route}) => {
  return (
    <Link to={route}>
      <Icon name="arrow left" size="large" />
    </Link>
  );
};

export default GoBack;
