import React from 'react';
import { useTranslation } from 'react-i18next';
import { Step, Icon, List, Button } from 'semantic-ui-react';

const ConfirmationStep = ({ active, completed }) => {
  const { t } = useTranslation();

  return (
    <Step active={active} completed={completed}>
      <Icon name='check circle' />
      <Step.Content>
        <Step.Title>{t('BUILDINGS.NEW.CONFIRMATION_STEP.TITLE')}</Step.Title>
        <Step.Description>
          {t('BUILDINGS.NEW.CONFIRMATION_STEP.DESCRIPTION')}
        </Step.Description>
      </Step.Content>
    </Step>
  );
};

const ConfirmationContent = ({ active, building, companies, prev, next }) => {
  const { t } = useTranslation();
  const company = companies.find(company => company.value === building.company);
  const { address } = building.location;
  const fullAddress = `${address.street}, ${address.number}, ${address.neighborhood}, ${address.city} - ${address.state}`;

  return active && (
    <>
      <List divided>
        <List.Item>
          <List.Header>{t('BUILDINGS.NEW.CONFIRMATION_STEP.COMPANY')}</List.Header>
          {company.text}
        </List.Item>
        <List.Item>
          <List.Header>{t('BUILDINGS.NEW.CONFIRMATION_STEP.CNPJ')}</List.Header>
          {building.cnpj}
        </List.Item>
        <List.Item>
          <List.Header>{t('BUILDINGS.NEW.CONFIRMATION_STEP.NAME')}</List.Header>
          {building.name}
        </List.Item>
        <List.Item>
          <List.Header>{t('BUILDINGS.NEW.CONFIRMATION_STEP.TIME_ZONE')}</List.Header>
          {building.timezone}
        </List.Item>
        <List.Item>
          <List.Header>{t('BUILDINGS.NEW.CONFIRMATION_STEP.ADDRESS')}</List.Header>
          {fullAddress}
        </List.Item>
        <List.Item>
          <List.Header>{t('BUILDINGS.NEW.CONFIRMATION_STEP.ORIENTATION')}</List.Header>
          {t(`GLOBAL.LABELS.${building.orientation}`)}
        </List.Item>
        <List.Item>
          <List.Header>{t('BUILDINGS.NEW.CONFIRMATION_STEP.BLOCK_LABEL')}</List.Header>
          {t(`GLOBAL.LABELS.${building.blockLabel}`)}
        </List.Item>
        <List.Item>
          <List.Header>{t('BUILDINGS.NEW.CONFIRMATION_STEP.UNIT_LABEL')}</List.Header>
          {t(`GLOBAL.LABELS.${building.unitLabel}`)}
        </List.Item>
        <List.Item>
          <List.Header>{t('BUILDINGS.NEW.CONFIGURATION_STEP.USER_TYPE_RULE.LABEL')}</List.Header>
          {t(`GLOBAL.LABELS.${building.userNameLabel}`)}
        </List.Item>
        <List.Item>
          <List.Header>{t('BUILDINGS.NEW.CONFIRMATION_STEP.BLOCKS_AMOUNT')}</List.Header>
          {building.blocks.length}
        </List.Item>
        <List.Item>
          <List.Header>{t('BUILDINGS.NEW.CONFIRMATION_STEP.UNITS_AMOUNT')}</List.Header>
          {building.units.length}
        </List.Item>
        <List.Item>
          <List.Header>{t('BUILDINGS.NEW.CONFIRMATION_STEP.PLAN_TYPES.MANAGEMENT')}</List.Header>
          {t(`BUILDINGS.NEW.CONFIRMATION_STEP.PLANS.${building.configuration.plans.management}`)}
        </List.Item>
        <List.Item>
          <List.Header>{t('BUILDINGS.NEW.CONFIRMATION_STEP.PLAN_TYPES.ACCESS_CONTROL')}</List.Header>
          {t(`BUILDINGS.NEW.CONFIRMATION_STEP.PLANS.${building.configuration.plans.accessControl}`)}
        </List.Item>
        <List.Item>
          <List.Header>{t('BUILDINGS.NEW.CONFIRMATION_STEP.ASSEMBLIES')}</List.Header>
          {building.modules.assemblies?.enabled ? 'Sim' : 'Não'}
        </List.Item>
        <List.Item>
          <List.Header>OCR - Validar CNH</List.Header>
          {building.modules.ocrDocuments.cnhValidate.enabled ? 'Sim' : 'Não'}
        </List.Item>
        <List.Item>
          <List.Header>{t('BUILDINGS.NEW.PLAN_STEP.MODULES.MAINTENANCE_PLAN') + ' - Convencional'}</List.Header>
          {building.modules.maintenance.enabled ? 'Sim' : 'Não'}
        </List.Item>
        <List.Item>
          <List.Header>Plano de Manutenção - Exclusivo</List.Header>
          {building.modules.maintenanceExclusive.enabled ? 'Sim' : 'Não'}
        </List.Item>
        <List.Item>
          <List.Header>Plano de Manutenção - Notificações EMAIL</List.Header>
          {building.modules.maintenance.notifications.email ? 'Sim' : 'Não'}
        </List.Item>
        <List.Item>
          <List.Header>Plano de Manutenção - Notificações WHATSAPP</List.Header>
          {building.modules.maintenance.notifications.whatsapp ? 'Sim' : 'Não'}
        </List.Item>
        {/* <List.Item>
          <List.Header>{t('BUILDINGS.NEW.CONFIRMATION_STEP.ACTIVE_MODULES')}</List.Header>
          {building.units.length}
        </List.Item> */}
      </List>
      <Button primary onClick={prev}>{t('BUILDINGS.NEW.PREV')}</Button>
      <Button primary type='button' onClick={() => next(building)}>
        {t('BUILDINGS.NEW.CONFIRMATION_STEP.CONFIRM')}
      </Button>
    </>
  );
};

export { ConfirmationStep, ConfirmationContent };
