import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Step, Icon, Segment, Message, Progress, Button } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import axios from '../../../../../config/axios';

import './styles.css';

const ProgressStep = ({ active }) => {
  const { t } = useTranslation();

  return (
    <Step active={active}>
      <Icon name='tasks' />
      <Step.Content>
        <Step.Title>{t('BUILDINGS.NEW.PROGRESS_STEP.TITLE')}</Step.Title>
        <Step.Description>
          {t('BUILDINGS.NEW.PROGRESS_STEP.DESCRIPTION')}
        </Step.Description>
      </Step.Content>
    </Step>
  );
};

const ProgressContent = ({ active, building, prev }) => {
  const history = useHistory();
  const [progress, setProgress] = useState(0);
  const [logs, setLogs] = useState([]);
  const { t } = useTranslation();
  const total = 1 + building.blocks.length + building.units.length;
  const addLog = log => setLogs(currentLogs => [...currentLogs, log]);
  const basePromise = promise => new Promise(resolve => setTimeout(async () => {
    try {
      const { data: result } = await promise();
      setProgress(currentProgress => currentProgress + (100 / total));
      resolve(result);
    } catch (error) {
      // resolve(error);
      addLog({
        color: 'red',
        message: `Error: ${error.message}`
      });
    }
  }, 1000));
  const createBuilding = async () => {
    const data = {
      name: building.name,
      orientation: building.orientation,
      labels: {
        block: building.blockLabel,
        unit: building.unitLabel,
        user: building.userNameLabel
      },
      blockLabel: building.blockLabel,
      apartmentLabel: building.unitLabel,
      userLabel: building.userNameLabel,
      location: building.location,
      documents: { cnpj: building.cnpj },
      company: building.company,
      timezone: building.timezone,
      configuration: { modules: building.modules }
    };

    console.log('buildings', data)
    
    const result = await basePromise(() => axios.post('buildings', data));

    return result._id;
  };
  const createUnits = (block, blockId, buildingId) => {
    const units = building.units.filter(unit => {
      if (unit.floor) {
        const floor = building.floors.find(floor => floor.id === unit.floor);
        return floor.block === block.id;
      }

      return unit.block === block.id;
    });

    return units.reduce(async (previousPromise, { identifier }) => {
      await previousPromise;

      addLog({
        color: 'yellow',
        message: `Criação da unidade ${block.name} - ${identifier} iniciada.`
      });

      const data = {
        identifier,
        block: blockId,
        condo: buildingId
      };

      console.log('units', data)

      await basePromise(() => axios.post('units', data));

      addLog({
        color: 'green',
        message: `Criação da unidade ${block.name} - ${identifier} finalizada com sucesso.`
      });

      return;
    }, Promise.resolve());
  };
  const createBlocks = async buildingId => {
    return building.blocks.reduce(async (previousPromise, block) => {
      await previousPromise;

      addLog({
        color: 'yellow',
        message: `Criação do bloco ${block.name} iniciada.`
      });

      const data = { name: block.name, condo: buildingId };

      console.log('blocks', data)

      const result = await basePromise(() => axios.post('blocks', data));

      await createUnits(block, result._id, buildingId);

      addLog({
        color: 'green',
        message: `Criação do bloco ${block.name} finalizada com sucesso.`
      });

      return;
    }, Promise.resolve());
  };
  const submit = async () => {
    addLog({
      color: 'yellow',
      message: `Criação do condomínio ${building.name} iniciada.`
    });

    const buildingId = await createBuilding();

    await createBlocks(buildingId);

    addLog({
      color: 'green',
      message: `Criação do condomínio ${building.name} finalizada com sucesso.`
    });
  };
  const finish = () => history.push('/buildings');

  return active && (
    <>
      <Segment className='logs'>
        {logs.map((log, index) => (
          <Message
            key={index}
            color={log.color}
            size='small'
          >
            {log.message}
          </Message>
        ))}
      </Segment>
      <Progress
        indicating
        percent={Math.round(progress)}
        progress='percent'
      />
      {progress === 0 && (
        <>
          <Button primary onClick={prev}>{t('BUILDINGS.NEW.PREV')}</Button>
          <Button primary type='button' onClick={() => submit(building)}>
            {t('BUILDINGS.NEW.PROGRESS_STEP.START_CREATION')}
          </Button>
        </>
      )}
      <Button
        primary
        type='button'
        disabled={Math.round(progress) !== 100}
        onClick={finish}
      >
        {t('BUILDINGS.NEW.PROGRESS_STEP.FINISH')}
      </Button>
    </>
  );
};

export { ProgressStep, ProgressContent };
