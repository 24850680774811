import React from "react";
import ReactDOM from "react-dom";

import "./styles.css";

export function Loader({ isLoading }) {
  if (!isLoading) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className="overlay">
      <div className="loader">
      </div>
    </div>,
    document.getElementById("loader-root")
  );
}
