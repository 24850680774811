import React, { useRef } from 'react';
import { Form as Unform } from '@unform/web';
import * as Yup from 'yup';

const Form = ({ onSubmit, schema, children, ...rest }) => {
  const formRef = useRef(null);
  const submit = async (data, ...args) => {
    if(!schema) return onSubmit(data, ...args);

    try {
      formRef.current.setErrors({});
      
      await schema.validate(data, { abortEarly: false });

      onSubmit(data, ...args);
    } catch (err) {
      const validationErrors = {};

      if(err instanceof Yup.ValidationError) {
        err.inner.forEach(({ path, message }) => validationErrors[path] = message);
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <Unform
      ref={formRef}
      onSubmit={submit}
      className='ui form'
      {...rest}
    >
      {children}
    </Unform>
  );
};

export default Form;
