import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Menu, Icon } from 'semantic-ui-react';

const PrivateRoutes = ({ children, logout, ...rest }) => (
  <Route {...rest}>
    <Menu inverted className='application-menu'>
      <Menu.Menu position='right'>
        <Menu.Item onClick={logout}>
          <Icon name='log out' />
        </Menu.Item>
      </Menu.Menu>
    </Menu>
    <Switch>{children}</Switch>
  </Route>
);

export default PrivateRoutes;
