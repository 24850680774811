import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Container,
  Segment,
  Header,
  Button,
  Divider,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import Tabs from "./components/tabs";
import axios from "../../../config/axios";
import InputSearch from "./components/search";

import "./styles.css";

const Buildings = () => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState({
    data: [],
    total: 0,
    limit: 15,
    skip: 0,
  });

  const fetch = useCallback(
    async (query = {}) => {
      setLoading(true);

      const params = {
        ...query,
        active: tab !== 1,
        $limit: 10000,
        $select: ["company", "name", "location.address", "createAt", "active"],
        $populate: [
          {
            path: "company",
            select: ["name"]
          },
        ],
      };
      const { data: result } = await axios.get("buildings", { params });
      setLoading(false);
      setResult(result);
    },
    [tab]
  );

  useEffect(() => {
    fetch({ active: true });
  }, [fetch]);

  function handleSearchTerm(event) {
    setSearchTerm(event.target.value);
  }

  const filteredCondos = useMemo(() => {
    const data = result.data?.filter((condos) =>
      condos.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return {
      ...result,
      data,
    };
  }, [result, searchTerm]);

  return (
    <Container className="page">
      <Segment.Group>
        <Segment className="container-header">
          <Header as="h2">{t("BUILDINGS.LIST.TITLE")}</Header>
          <Link to="/buildings/new">
            <Button primary floated="right">
              {t("BUILDINGS.LIST.NEW_BUTTON")}
            </Button>
          </Link>
        </Segment>
        <Segment>
          <Dimmer inverted active={loading}>
            <Loader />
          </Dimmer>

          <InputSearch
            onChange={handleSearchTerm}
            placeholder={t("FILTER.PLACEHOLDER")}
          />
          <Divider clearing />
          {!loading && (
            <Tabs 
            tab={tab} 
            result={result} 
            filteredCondos={filteredCondos} 
            setTab={setTab}
            searchTerm={searchTerm} />
          )}
        </Segment>
      </Segment.Group>
    </Container>
  );
};

export default Buildings;
