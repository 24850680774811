import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tab } from 'semantic-ui-react';
import General from '../general';
import Plan from '../plan';

const Tabs = ({
  building,
  changeBuildingPlan,
  activateOrInactivateBuildingModule
}) => {
  const { t } = useTranslation();
  const panes = [{
    menuItem: t('BUILDINGS.DETAILS.GENERAL_TAB'),
    render: () => (
      <General building={building} />
    )
  }, {
    menuItem: t('BUILDINGS.DETAILS.PLAN_TAB'),
    render: () => (
      <Plan
        building={building}
        changeBuildingPlan={changeBuildingPlan}
        activateOrInactivateBuildingModule={activateOrInactivateBuildingModule}
      />
    )
  }];

  return (
    <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
  );
};

export default Tabs;
