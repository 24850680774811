export const Validator = {
  isValidCPF: (number) => {
    number = number.replace(/[\s.-]*/gim, "");
    let sum;
    let rest;
    let i;
    sum = 0;
    if (number === "00000000000") return false;

    for (i = 1; i <= 9; i++)
      sum = sum + parseInt(number.substring(i - 1, i)) * (11 - i);
    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) rest = 0;
    if (rest !== parseInt(number.substring(9, 10))) return false;

    sum = 0;
    for (i = 1; i <= 10; i++)
      sum = sum + parseInt(number.substring(i - 1, i)) * (12 - i);
    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) rest = 0;
    if (rest !== parseInt(number.substring(10, 11))) return false;
    return true;
  },
  isValidEmail: (email) => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  },
  isValidPhone: (phone) => {
    const regex =
      /^(?:(?:\+|00)?(55)\s?)?(?:(?:\(?[1-9][0-9]\)?)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/;
    return regex.test(phone);
  },
};
