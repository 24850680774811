export default {
  translation: {
    GLOBAL: {
      LABELS: {
        HORIZONTAL: 'Horizontal',
        VERTICAL: 'Vertical',
        BLOCK: 'Bloco',
        TOWER: 'Torre',
        QUATRAIN: 'Quadra',
        STREET: 'Rua',
        UNIT: 'Unidade',
        APARTMENT: 'Apartamento',
        BUNGALOW: 'Bangalô',
        SECTOR: 'Setor',
        DEPARTAMENT: 'Departamento',
        HOUSE: 'Casa',
        LOT: 'Lote',
        COMMERCIAL_ROOM: 'Sala',
        HOTEL_ROOM: 'Quarto',
        LETTERS: 'Letras',
        NUMBERS: 'Números',
        DWELLER: 'Morador',
        USER: 'Usuário',
        COLLABORATOR: 'Colaborador',
        SALISTA: 'Salista',
        EMPLOYEE: 'Funcionário',
      }
    },
    LOGIN: {
      EMAIL: {
        LABEL: 'E-mail',
        PLACEHOLDER: 'E-mail'
      },
      PASSWORD: {
        LABEL: 'Senha',
        PLACEHOLDER: 'Senha'
      },
      BUTTON: 'Login'
    },
    FILTER: {
      LABEL: 'Pesquisar',
      PLACEHOLDER: 'Pesquisar Condomínio'
    },
    BUILDINGS: {
      LIST: {
        TITLE: 'Condomínios',
        NEW_BUTTON: 'Novo',
        ACTIVE_TAB: 'Ativos',
        INACTIVE_TAB: 'Inativos',
        NAME: 'Nome',
        WHITELABEL: 'Whitelabel',
        CITY: 'Cidade',
        STATE: 'Estado',
        CREATED_AT: 'Data de criação'
      },
      DETAILS: {
        GENERAL_TAB: 'Geral',
        PLAN_TAB: 'Plano',
        GENERAL: {
          COMPANY: 'Empresa',
          CNPJ: 'CNPJ',
          NAME: 'Nome',
          TIME_ZONE: 'Fuso horário',
          ADDRESS: 'Endereço',
          ORIENTATION: 'Orientação',
          BLOCK_LABEL: 'Label do bloco',
          UNIT_LABEL: 'Label da unidade',
          USER_LABEL: 'Label de usuários',
          BLOCKS_AMOUNT: 'Quantidade de blocos',
          UNITS_AMOUNT: 'Quantidade de unidades'
        },
        PLAN: {
          LABEL: 'Plano',
          PLACEHOLDER: 'Plano',
          MODULES_LABEL: 'Módulos',
          PLAN_TYPES: {
            MANAGEMENT: 'Gestão',
            ACCESS_CONTROL: 'Controle de Acesso',
            EXTRAS: 'Extras'
          },
          PLANS: {
            CUSTOM: 'Customizado',
            BASIC: 'Basic',
            PLUS: 'Plus',
            NONE: 'Nenhum'
          },
          MODULES: {
            ANNOUNCEMENTS: 'Avisos',
            ASSEMBLIES: 'Assembleias',
            DOCUMENTS: 'Documentos',
            CLASSIFIEDS: 'Classificados',
            POLLS: 'Enquetes',
            PHONES: 'Telefones úteis',
            TICKETS: 'Ocorrências',
            BOOKINGS: 'Reservas',
            OBJECTS_CONTROL: 'Controle de objetos',
            WATER_MEASUREMENTS: 'Consumo de água',
            GAS_MEASUREMENTS: 'Consumo de gás',
            POWER_MEASUREMENTS: 'Consumo de energia',
            ACCESS_CONTROL: 'Portaria',
            RENTS: 'Locações',
            LINEAR: 'Linear',
            SUPERLOGICA: 'Superlogica',
            VERDE_ACQUA: 'Verde Acqua',
            GOSOFT: 'Gosoft',
            MAINTENANCE_PLAN: 'Plano de Manutenção',
            GESTART: 'Gestart',
            PROSINDICO: 'ProSindico',
            SHIFT_LOGS: 'Registro de turno',
            PACKAGES: 'Encomendas',
            GET_NINJAS: 'GetNinjas',
            PEOPLE_ON_BOARD: 'Pessoas Presentes',
            WARNINGS: 'Advertências'
          }
        }
      },
      NEW: {
        TITLE: 'Cadastro de condomínio',
        COMPANY_STEP: {
          TITLE: 'Empresa',
          DESCRIPTION: 'Whitelabel do condomínio.',
          COMPANY: {
            LABEL: 'Empresa',
            PLACEHOLDER: 'Selecione a empresa'
          }
        },
        CONFIGURATION_STEP: {
          TITLE: 'Configuração',
          DESCRIPTION: 'Definição de blocos e unidades.',
          ORIENTATION: {
            LABEL: 'Orientação',
            PLACEHOLDER: 'Selecione a orientação'
          },
          BLOCK_LABEL: {
            LABEL: 'Label do bloco',
            PLACEHOLDER: 'Selecione o label do bloco'
          },
          UNIT_LABEL: {
            LABEL: 'Label da unidade',
            PLACEHOLDER: 'Selecione o label da unidade'
          },
          BLOCKS: {
            LABEL: 'Blocos',
            PLACEHOLDER: 'Blocos'
          },
          FLOORS: {
            LABEL: 'Andares',
            PLACEHOLDER: 'Andares'
          },
          UNITS_PER_FLOOR: {
            LABEL: 'Unidades por andar',
            PLACEHOLDER: 'Unidades por andar'
          },
          UNITS: {
            LABEL: 'Unidades',
            PLACEHOLDER: 'Unidades'
          },
          BLOCK_NAME_RULE: {
            LABEL: 'Regra de nome dos blocos',
            PLACEHOLDER: 'Selecione a regra de nome dos blocos'
          },
          UNIT_NAME_RULE: {
            LABEL: 'Regra de nome das unidades',
            PLACEHOLDER: 'Selecione a regra de nome das unidades'
          },
          USER_TYPE_RULE: {
            LABEL: 'Regra de nome dos usuários',
            PLACEHOLDER: 'Selecione a regra de nome dos usuários'
          }
        },
        CONFIRMATION_STEP: {
          TITLE: 'Confirmação',
          DESCRIPTION: 'Verifique se todas as informações estão corretas.',
          COMPANY: 'Empresa',
          CNPJ: 'CNPJ',
          NAME: 'Nome',
          TIME_ZONE: 'Fuso horário',
          ADDRESS: 'Endereço',
          ORIENTATION: 'Orientação',
          BLOCK_LABEL: 'Label do bloco',
          UNIT_LABEL: 'Label da unidade',
          BLOCKS_AMOUNT: 'Quantidade de blocos',
          UNITS_AMOUNT: 'Quantidade de unidades',
          ACTIVE_MODULES: 'Módulos ativos',
          PLAN_TYPES: {
            MANAGEMENT: 'Gestão',
            ACCESS_CONTROL: 'Controle de Acesso'
          },
          PLANS: {
            CUSTOM: 'Customizado',
            BASIC: 'Basic',
            PLUS: 'Plus',
            NONE: 'Nenhum'
          },
          ASSEMBLIES: 'Assembleias',
          CONFIRM: 'Confirmar'
        },
        GENERAL_STEP: {
          TITLE: 'Geral',
          DESCRIPTION: 'Nome, endereço e outras informações.',
          CNPJ: {
            LABEL: 'CNPJ',
            PLACEHOLDER: 'CNPJ'
          },
          NAME: {
            LABEL: 'Nome',
            PLACEHOLDER: 'Nome'
          },
          TIME_ZONE: {
            LABEL: 'Fuso horário',
            PLACEHOLDER: 'Selecione o fuso horário'
          },
          STREET: {
            LABEL: 'Rua',
            PLACEHOLDER: 'Rua'
          },
          NUMBER: {
            LABEL: 'Número',
            PLACEHOLDER: 'Número'
          },
          NEIGHBORHOOD: {
            LABEL: 'Bairro',
            PLACEHOLDER: 'Bairro'
          },
          POSTAL_CODE: {
            LABEL: 'Código postal',
            PLACEHOLDER: 'Código postal'
          },
          CITY: {
            LABEL: 'Cidade',
            PLACEHOLDER: 'Cidade'
          },
          STATE: {
            LABEL: 'Estado',
            PLACEHOLDER: 'Estado'
          },
          LATITUDE: {
            LABEL: 'Latitude',
            PLACEHOLDER: 'Latitude'
          },
          LONGITUDE: {
            LABEL: 'Longitude',
            PLACEHOLDER: 'Longitude'
          }
        },
        PLAN_STEP: {
          TITLE: 'Plano',
          DESCRIPTION: 'Plano e módulos aos quais o condomínio vai ter acesso.',
          PLAN_TYPES: {
            MANAGEMENT: 'Gestão',
            ACCESS_CONTROL: 'Controle de Acesso',
            EXTRAS: 'Extras'
          },
          PLANS: {
            CUSTOM: 'Customizado',
            BASIC: 'Basic',
            PLUS: 'Plus',
            NONE: 'Nenhum'
          },
          MODULES: {
            ANNOUNCEMENTS: 'Avisos',
            ASSEMBLIES: 'Assembleias',
            DOCUMENTS: 'Documentos',
            CLASSIFIEDS: 'Classificados',
            POLLS: 'Enquetes',
            PHONES: 'Telefones úteis',
            TICKETS: 'Ocorrências',
            BOOKINGS: 'Reservas',
            OBJECTS_CONTROL: 'Controle de objetos',
            WATER_MEASUREMENTS: 'Consumo de água',
            GAS_MEASUREMENTS: 'Consumo de gás',
            POWER_MEASUREMENTS: 'Consumo de energia',
            MAINTENANCE_PLAN: 'Plano de Manutenção',
            ACCESS_CONTROL: 'Portaria',
            RENTS: 'Locações',
            LINEAR: 'Linear',
            SUPERLOGICA: 'Superlogica',
            VERDE_ACQUA: 'Verde Acqua',
            GOSOFT: 'Gosoft',
            SHIFT_LOGS: 'Registro de turno',
            PEOPLE_ON_BOARD: 'Pessoas Presentes',
            WARNING: 'Advertências'
          }
        },
        PROGRESS_STEP: {
          TITLE: 'Progresso',
          DESCRIPTION: 'Progresso da criação do condomínio.',
          START_CREATION: 'Iniciar criação',
          FINISH: 'Finalizar'
        },
        STRUCTURE_STEP: {
          TITLE: 'Estrutura',
          DESCRIPTION: 'Estrutura gerada de acordo com as configurações inseridas.',
          FLOOR: 'Andar',
          UNIT_MODAL: {
            EDIT_TITLE: 'Edição de unidade',
            NEW_TITLE: 'Nova unidade',
            IDENTIFIER: {
              LABEL: 'Identificador',
              PLACEHOLDER: 'Identificador'
            },
            CANCEL: 'Cancelar',
            SAVE: 'Salvar'
          },
          BLOCK_MODAL: {
            TITLE: 'Edição de bloco',
            NAME: {
              LABEL: 'Nome',
              PLACEHOLDER: 'Nome'
            },
            CANCEL: 'Cancelar',
            SAVE: 'Salvar'
          }
        },
        PREV: 'Anterior',
        NEXT: 'Próximo'
      }
    }
  }
};
