import axios from 'axios';
import qs from 'qs';

const instance = axios.create({
  baseURL: 'https://api.severinoapp.com/',
  //baseURL: 'https://api-staging.severinoapp.com/',
  //baseURL: 'http://localhost:3030/',
  // headers: {'X-Custom-Header': 'foobar'}
  crossDomain: true
});

instance.interceptors.request.use(config => ({
  ...config,
  paramsSerializer: params => qs.stringify(params, {
    arrayFormat: 'brackets',
    encode: false
  })
}));

// instance.interceptors.request.use(config => {
//   const token = localStorage.getItem('token');
//   const headers = { Authorization:  }

//   return token ? { ...config,  }

//   return config;
// }, error => Promise.reject(error));

export default instance;
