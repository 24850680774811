import React from 'react';
import { useTranslation } from 'react-i18next';
import { List } from 'semantic-ui-react';

const General = ({ building }) => {
  const { t } = useTranslation();
  const { address } = building.location;
  const fullAddress = `${address.street}, ${address.number}, ${address.neighborhood}, ${address.city} - ${address.state}`;

  return (
    <List divided>
      <List.Item>
        <List.Header>{t('BUILDINGS.DETAILS.GENERAL.COMPANY')}</List.Header>
        {building.company.name}
      </List.Item>
      {((building.documents && building.documents.cnpj) || building.cnpj) && (
        <List.Item>
          <List.Header>{t('BUILDINGS.DETAILS.GENERAL.CNPJ')}</List.Header>
          {building.documents ? building.documents.cnpj : building.cnpj}
        </List.Item>
      )}
      <List.Item>
        <List.Header>{t('BUILDINGS.DETAILS.GENERAL.NAME')}</List.Header>
        {building.name}
      </List.Item>
      <List.Item>
        <List.Header>{t('BUILDINGS.DETAILS.GENERAL.TIME_ZONE')}</List.Header>
        {building.timezone}
      </List.Item>
      <List.Item>
        <List.Header>{t('BUILDINGS.DETAILS.GENERAL.ADDRESS')}</List.Header>
        {fullAddress}
      </List.Item>
      <List.Item>
        <List.Header>{t('BUILDINGS.DETAILS.GENERAL.ORIENTATION')}</List.Header>
        {t(`GLOBAL.LABELS.${building.orientation}`)}
      </List.Item>
      <List.Item>
        <List.Header>{t('BUILDINGS.DETAILS.GENERAL.BLOCK_LABEL')}</List.Header>
        {t(`GLOBAL.LABELS.${building.labels.block}`)}
      </List.Item>
      <List.Item>
        <List.Header>{t('BUILDINGS.DETAILS.GENERAL.UNIT_LABEL')}</List.Header>
        {t(`GLOBAL.LABELS.${building.labels.unit}`)}
      </List.Item>
      <List.Item>
        <List.Header>{t('BUILDINGS.DETAILS.GENERAL.USER_LABEL')}</List.Header>
        {t(`GLOBAL.LABELS.${building.labels.user}`)}
      </List.Item>
      {/* <List.Item>
        <List.Header>{t('BUILDINGS.DETAILS.GENERAL.BLOCKS_AMOUNT')}</List.Header>
        {building.blocks.length}
      </List.Item>
      <List.Item>
        <List.Header>{t('BUILDINGS.DETAILS.GENERAL.UNITS_AMOUNT')}</List.Header>
        {building.units.length}
      </List.Item> */}
    </List>
  );
};

export default General;
