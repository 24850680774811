import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Tab, Table, Menu, Icon } from "semantic-ui-react";

const BuildingsTable = ({ result, filteredCondos, searchTerm }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const navigateToDetails = (id) => history.push(`buildings/${id}`);
  const pageNumbers = [];

  const [currentPage, setCurrentPage] = useState(1);
  const [condosPerPage] = useState(15);
  const [currentCondos, setCurrentCondos] = useState({});

  useEffect(() => {
    const condos = searchTerm === "" ? result : filteredCondos;

    if (searchTerm) {
      setCurrentPage(1);
    }

    const data = condos?.data?.sort((a, b) => a.name.localeCompare(b.name));
    const indexOfLastCondo = currentPage * condosPerPage;
    const indexOfFirstCondo = indexOfLastCondo - condosPerPage;
    const condosResult = data?.slice(indexOfFirstCondo, indexOfLastCondo);
    setCurrentCondos({ ...result, data: condosResult });
  }, [result, currentPage, condosPerPage, searchTerm, filteredCondos]);

  useMemo(() => {
    for (let i = 1; i <= Math.ceil(result?.data.length / condosPerPage); i++) {
      pageNumbers.push(i);
    }
  }, [result, condosPerPage, pageNumbers]);

  const paginate = (param) => {
    switch (param) {
      case "next":
        setCurrentPage((prevState) => prevState + 1);
        break;
      case "prev":
        setCurrentPage((prevState) => prevState - 1);
        break;
      case "last":
        setCurrentPage(pageNumbers.length);
        break;
      case "first":
        setCurrentPage(1);
        break;
      default:
        return;
    }
  };

  return (
    <Tab.Pane as="div">
      <Table celled selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              {t("BUILDINGS.LIST.NAME")}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t("BUILDINGS.LIST.WHITELABEL")}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t("BUILDINGS.LIST.CITY")}
              </Table.HeaderCell>
            <Table.HeaderCell>
              {t("BUILDINGS.LIST.STATE")}
              </Table.HeaderCell>
            <Table.HeaderCell>
              {t("BUILDINGS.LIST.CREATED_AT")}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {currentCondos?.data?.map((building) => (
            <Table.Row
              key={building._id}
              onClick={() => navigateToDetails(building._id)}
            >
              <Table.Cell>{building.name}</Table.Cell>
              <Table.Cell>{building.company.name}</Table.Cell>
              <Table.Cell>{building.location.address.city}</Table.Cell>
              <Table.Cell>{building.location.address.state}</Table.Cell>
              <Table.Cell>
                {moment(building.createAt).format("DD/MM/YYYY")}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>

        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="5">
              <Menu floated="right" pagination>
                <Menu.Item
                  icon
                  as="a"
                  disabled={currentPage === 1}
                  onClick={() => paginate("first")}
                >
                  <Icon name="angle double left" />
                </Menu.Item>
                <Menu.Item
                  icon
                  as="a"
                  disabled={currentPage === 1}
                  onClick={() => paginate("prev")}
                >
                  <Icon name="chevron left" />
                </Menu.Item>
                <Menu.Item active as="a">
                  {currentPage}
                </Menu.Item>
                <Menu.Item
                  icon
                  as="a"
                  disabled={currentPage >= pageNumbers.length}
                  onClick={() => paginate("next")}
                >
                  <Icon name="chevron right" />
                </Menu.Item>
                <Menu.Item
                  icon
                  as="a"
                  disabled={currentPage === pageNumbers.length}
                  onClick={() => paginate("last")}
                >
                  <Icon name="angle double right" />
                </Menu.Item>
              </Menu>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Tab.Pane>
  );
};

export default BuildingsTable;
