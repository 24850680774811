import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tab } from 'semantic-ui-react';
import Table from '../table';

const Tabs = ({ tab, result, setTab, filteredCondos, searchTerm }) => {
  const { t } = useTranslation();
  const render = () => (
    <Table
      result={result}
      filteredCondos={filteredCondos}
      searchTerm={searchTerm}
    />
  );
  const panes = [{
    menuItem: t('BUILDINGS.LIST.ACTIVE_TAB'),
    render
  }, {
    menuItem: t('BUILDINGS.LIST.INACTIVE_TAB'),
    render
  }];
  const onTabChange = (_, { activeIndex }) => setTab(activeIndex);

  return (
    <Tab
      panes={panes}
      activeIndex={tab}
      menu={{ secondary: true, pointing: true }}
      onTabChange={onTabChange}
    />
  );
};

export default Tabs;
