import React, { useEffect, useRef } from 'react';
import { Form } from 'semantic-ui-react';
import { useField } from '@unform/core';

const Input = ({ name, label, ...rest }) => {

  const inputRef = useRef(null);
  const { fieldName, defaultValue = '', registerField, error } = useField(name);

  useEffect(() => {
    const config = {
      name: fieldName,
      ref: inputRef.current,
      path: 'value'
    };

    registerField(config);
  }, [fieldName, registerField]);

  return (
    <Form.Field error={!!error}>
      <label>{label}</label>
      <input
        ref={inputRef}
        defaultValue={defaultValue}
        {...rest}
      />
    </Form.Field>
  );
};

export default Input;
