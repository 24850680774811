import React from "react";
import './styles.css'

const InputSearch = ({ placeholder, onChange }) => {
  return (
    <>
      <input onChange={onChange} className="input-search" placeholder={placeholder} />
    </>
  );
};

export default InputSearch;
