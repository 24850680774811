import React from 'react';
import { useTranslation } from 'react-i18next';
import { Segment, Header, Button } from 'semantic-ui-react';

import './styles.css';

const Units = ({ building, related, relatedAttribute, removeUnit, showUnitModal }) => {
  const { t } = useTranslation();

  return (
    <Segment.Group>
      {building.units.filter(unit => unit[relatedAttribute] === related.id).map(unit => (
        <Segment key={unit.id} className='unit'>
          <Header as='h5' className='unit-header'>
            {t(`GLOBAL.LABELS.${building.unitLabel}`)} {unit.identifier}
          </Header>
          <Button
            type='button'
            className='unit-action-button'
            icon='pencil'
            onClick={() => showUnitModal(unit)}
          />
          <Button
            type='button'
            className='unit-action-button'
            icon='trash alternate'
            color='red'
            onClick={() => removeUnit(unit)}
          />
        </Segment>
      ))}
    </Segment.Group>
  );
};

export default Units;
