import React, { useState, useEffect, useCallback } from "react";
import { useRouteMatch } from "react-router-dom";
import { Container, Segment, Header, Checkbox } from "semantic-ui-react";
import Tabs from "./components/tabs";
import axios from "../../../config/axios";

import "./styles.css";
import GoBack from "../../../components/go-back";

const BuildingsDetails = () => {
  const match = useRouteMatch();
  const [building, setBuilding] = useState({
    documents: {},
    company: {},
    location: { address: {} },
    labels: {},
    blocks: [],
  });
  const { id } = match.params;
  const fetchBuilding = useCallback(async () => {
    const params = {
      $select: [
        "company",
        "name",
        "location.address",
        "createAt",
        "active",
        "labels",
        "orientation",
        "timezone",
        "configuration",
      ],
      $populate: [
        {
          path: "company",
          select: ["name"]
        },
      ],
    };
    const { data: building } = await axios.get(`buildings/${id}`, { params });

    setBuilding(building);
  }, [id]);
  const updateBuilding = (data) => axios.patch(`buildings/${id}`, data);
  const changeBuildingPlan = async (plans) => {
    const configuration = { ...building.configuration, plans };

    updateBuilding({ configuration });
    setBuilding({ ...building, configuration });
  };
  const activateOrInactivateBuilding = async (_, { checked }) => {
    updateBuilding({ active: checked });
    setBuilding({ ...building, active: checked });
  };
  const activateOrInactivateBuildingModule =
    (attribute) =>
    async (_, { checked }) => {
      await updateBuilding({ [attribute]: checked });
      fetchBuilding();
    };

  useEffect(() => {
    fetchBuilding();
  }, [fetchBuilding]);

  return (
    <Container className="page">
      <Segment.Group>
        <Segment className="building-details-title-segment">
          <GoBack route="/" />
          <Header as="h2">{building.name}</Header>
          <Checkbox
            toggle
            label={building.active ? "Ativo" : "Desativado"}
            checked={building.active}
            onChange={activateOrInactivateBuilding}
          />
        </Segment>
        <Segment>
          <Tabs
            building={building}
            changeBuildingPlan={changeBuildingPlan}
            activateOrInactivateBuildingModule={
              activateOrInactivateBuildingModule
            }
          />
        </Segment>
      </Segment.Group>
    </Container>
  );
};

export default BuildingsDetails;
