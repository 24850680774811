import React, { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Button, Input, Select } from "semantic-ui-react";
import { cpfMask } from "../../common/cpf-mask";
import { Validator } from "../../common/validators";
import { phoneMask } from "../../common/phone-mask";
import { Loader } from "../../components/loader";
import logo from "../../assets/images/logo.png";
import Swal from "sweetalert2";
import "./styles.css";

export function Trial() {
  const [form, setForm] = useState({
    name: "",
    email: "",
    bornDate: "",
    phone: "",
    cpf: "",
    state: "",
    condoName: "",
    city: "",
    accept: false,
    // password: "",
    token: "",
  });
  const [states, setStates] = useState([]);
  const [citys, setCitys] = useState([]);
  const [confirmEmail, setConfirmEmail] = useState("");
  const [isEqualEmail, setIsEqualEmail] = useState(false);
  // const [confirmPassword, setConfirmPassword] = useState("");
  // const [isEqualPassword, setIsEqualPassword] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [invalidCpf, setInvalidCpf] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidPhone, setInvalidPhone] = useState(false);
  const [loading, setLoading] = useState(false);
  const arrUnits = ["101", "102", "201", "202"];

  useEffect(() => {
    isValidPhone();
    isValidEmail();
    isValidCPF();
    checkEmailEqual();
    // checkPasswordEqual();
    checkForm();

    if (
      invalidCpf ||
      // isEqualPassword ||
      isEqualEmail ||
      invalidEmail ||
      invalidPhone
    ) {
      setIsDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    form,
    confirmEmail,
    // confirmPassword,
    // isEqualPassword,
    invalidCpf,
    isEqualEmail,
    invalidEmail,
    invalidPhone,
  ]);

  useEffect(() => {
    fetch("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
      .then((res) => res.json())
      .then((data) => {
        const result = data.map((state) => ({
          key: state.sigla,
          value: state.sigla,
          text: state.nome,
        }));
        result.sort((a, b) => {
          if (a.value > b.value) {
            return 1;
          }
          if (a.value < b.value) {
            return -1;
          }

          return 0;
        });
        setStates(result);
      });
  }, []);

  useEffect(() => {
    setCitys([]);
    setForm({ ...form, city: "" });

    fetch(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${form.state}/municipios`
    )
      .then((res) => res.json())
      .then((data) => {
        const result = data.map((state) => ({
          key: state.sigla,
          value: state.nome,
          text: state.nome,
        }));
        result.sort((a, b) => {
          if (a.value > b.value) {
            return 1;
          }
          if (a.value < b.value) {
            return -1;
          }

          return 0;
        });
        setCitys(result);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.state]);

  async function createBlock(idCondo, name) {
    const data = {
      name: name,
      condo: idCondo,
    };

    const id = await fetch(
      //"https://api-staging.severinoapp.com/blocks/acess-trial",
      "https://api.severinoapp.com/blocks/acess-trial",
      //"http://localhost:3030/blocks/acess-trial",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then(async (response) => {
        let res = await response.json();

        return res._id;
      })
      .catch(() => {
        setLoading(false);

        return Swal.fire({
          title: "Erro!",
          text: "Não foi possível realizar o cadastro",
          icon: "error",
          confirmButtonText: "Fechar",
          confirmButtonColor: "#2b5c9e",
        });
      });

    return id;
  }

  async function createUnit(idCondo, idBlock, identifier) {
    const data = {
      identifier: identifier,
      block: idBlock,
      condo: idCondo,
    };

    await fetch(
      //"https://api-staging.severinoapp.com/units/acess-trial",
      "https://api.severinoapp.com/units/acess-trial",
      //"http://localhost:3030/units/acess-trial",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    ).then(async (response) => {
      let data = await response.json();

      if (!data._id) {
        setLoading(false);

        return Swal.fire({
          title: "Erro!",
          text: "Não foi possível realizar o cadastro",
          icon: "error",
          confirmButtonText: "Fechar",
          confirmButtonColor: "#2b5c9e",
        });
      }
    });
  }

  async function createAdm(idCondo) {
    const admDefault = {
      role: "MANAGER",
      receiveEmail: true,
      email: form.email,
      name: form.name,
      cpf: form.cpf,
      phone: form.phone,
      birth: form.bornDate,
      building: idCondo,
      emailCondos: [idCondo],
    };

    const userId = await fetch(
      //"https://api-staging.severinoapp.com/managers/acess-trial",
      "https://api.severinoapp.com/managers/acess-trial",
      //"http://localhost:3030/managers/acess-trial",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(admDefault),
      }
    ).then(async (response) => {
      let data = await response.json();

      if (data._id) {
        return data._id;
      } else {
        return undefined;
      }
    });

    if (userId) {
      Swal.fire({
        title: "Condomínio teste cadastrado com sucesso!",
        text: "Em instantes você receberá por e-mail uma senha para acesso ao severino App!",
        icon: "success",
        confirmButtonText: "Fechar",
        confirmButtonColor: "#2b5c9e",
        didClose: () => {
          setLoading(false);
          window.location.reload();
        },
      });
    } else {
      Swal.fire({
        title: "Erro!",
        text: "Não foi possível realizar o cadastro",
        icon: "error",
        confirmButtonText: "Fechar",
        confirmButtonColor: "#2b5c9e",
      });
      setLoading(false);
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setLoading(true);

    const condoDefault = {
      name: form.condoName,
      email: form.email,
      cpf: form.cpf,
      temporary: true,
      orientation: "VERTICAL",
      labels: {
        block: "BLOCK",
        unit: "APARTMENT",
        user: "DWELLER",
      },
      blockLabel: "BLOCK",
      apartmentLabel: "APARTMENT",
      userLabel: "DWELLER",
      location: {
        address: {
          street: "Rua teste",
          number: "10",
          neighborhood: "teste",
          postalCode: "00000000",
          city: form.city,
          state: form.state,
        },
        coordinates: ["1", "1"],
      },
      documents: {
        cnpj: "27865757000102",
      },
      company: "5bbe557296925a3d26a4b16e",
      timezone: "America/Brasilia",
      configuration: {
        modules: {
          announcements: {
            enabled: true,
          },
          tickets: {
            enabled: true,
          },
          documents: {
            enabled: true,
          },
          superlogica: {
            enabled: false,
          },
          gosoft: {
            enabled: false,
          },
          verdeAcqua: {
            enabled: false,
          },
          polls: {
            enabled: true,
          },
          phones: {
            enabled: true,
          },
          accessControl: {
            enabled: true,
          },
          rents: {
            enabled: true,
          },
          shiftLogs: {
            enabled: true,
          },
          assemblies: {
            enabled: true,
          },
          bookings: {
            enabled: true,
          },
          waterMeasurements: {
            enabled: true,
          },
          gasMeasurements: {
            enabled: true,
          },
          powerMeasurements: {
            enabled: true,
          },
          objectsControl: {
            enabled: true,
          },
          classifieds: {
            enabled: true,
          },
          linear: {
            enabled: true,
          },
        },
      },
    };

    await fetch(
      //"https://api-staging.severinoapp.com/buildings/acess-trial",
      "https://api.severinoapp.com/buildings/acess-trial",
      //"http://localhost:3030/buildings/acess-trial",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(condoDefault),
      }
    )
      .then(async (response) => {
        let data = await response.json();
        let idCondo = data._id;

        if (idCondo) {
          const blockA = await createBlock(idCondo, "A");
          const blockB = await createBlock(idCondo, "B");

          if (blockA && blockB) {
            arrUnits.forEach(
              async (unit) => await createUnit(idCondo, blockA, unit)
            );
            arrUnits.forEach(
              async (unit) => await createUnit(idCondo, blockB, unit)
            );
          }

          createAdm(idCondo);
        } else {
          setLoading(false);
          return Swal.fire({
            title: "Erro!",
            text: data.message,
            icon: "error",
            confirmButtonText: "Fechar",
            confirmButtonColor: "#2b5c9e",
          });
        }
      })
      .catch((err) => {
        setLoading(false);

        Swal.fire({
          title: "Erro!",
          text: "Não foi possível realizar o cadastro",
          icon: "error",
          confirmButtonText: "Fechar",
          confirmButtonColor: "#2b5c9e",
        });

        return;
      });
  }

  function checkForm() {
    const arr = Object.values(form);
    const validator = arr.filter((value) => value === "" || value === false);

    const result = validator.length ? true : false;
    setIsDisabled(result);
  }

  function isValidEmail() {
    if (form.email === "") {
      setInvalidEmail(false);
    }

    if (!Validator.isValidEmail(form.email) && form.email !== "") {
      setInvalidEmail(true);
      return;
    }

    setInvalidEmail(false);
  }

  function isValidCPF() {
    if (form.phone === "") {
      setInvalidCpf(false);
    }

    if (!Validator.isValidCPF(form.cpf) && form.cpf !== "") {
      setInvalidCpf(true);
      return;
    }

    setInvalidCpf(false);
  }

  function isValidPhone() {
    if (form.phone === "") {
      setInvalidPhone(false);
    }

    if (form.phone.length < 14 && form.phone !== "") {
      setInvalidPhone(true);
      return;
    }

    setInvalidPhone(false);
  }

  function checkEmailEqual() {
    if (confirmEmail === "") {
      setIsEqualEmail(false);
    }

    if (form.email !== confirmEmail) {
      setIsEqualEmail(true);
      return;
    }

    setIsEqualEmail(false);
  }

  // function checkPasswordEqual() {
  //   if (confirmPassword === "") {
  //     setIsEqualPassword(false);
  //   }

  //   if (form.password !== confirmPassword) {
  //     setIsEqualPassword(true);
  //     return;
  //   }

  //   setIsEqualPassword(false);
  // }

  return (
    <>
      <Loader isLoading={loading} />
      <div className="container-main">
        <section className="container-acess">
          <main className="content-acess">
            <img src={logo} alt="logomarca do Severino" />
            <div>
              <h1>Teste Grátis de 7 dias</h1>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="input-item">
                <label>
                  Nome Completo<b className="obrigatory">*</b>
                </label>
                <Input
                  className="input"
                  type="text"
                  placeholder="José da Silva"
                  onChange={(event) =>
                    setForm({ ...form, name: event.target.value })
                  }
                />
              </div>
              <div className="input-item-half">
                <div className="mr-3 mt-half-1">
                  <label>
                    Telefone<b className="obrigatory">*</b>
                  </label>
                  <Input
                    className="input"
                    type="phone"
                    placeholder="(99) 99999-9999"
                    maxLength="15"
                    minLength="14"
                    value={form.phone}
                    onChange={(event) =>
                      setForm({ ...form, phone: phoneMask(event.target.value) })
                    }
                  />
                  {invalidPhone && (
                    <div className="mt-1">
                      <span className="obrigatory mt">
                        O telefone informado não é válido
                      </span>
                    </div>
                  )}
                </div>
                <div className="mt-half-1">
                  <label>
                    Data de Nascimento<b className="obrigatory">*</b>
                  </label>
                  <Input
                    className="input"
                    type="date"
                    placeholder="01/01/1999"
                    onChange={(event) =>
                      setForm({ ...form, bornDate: event.target.value })
                    }
                  />
                </div>
              </div>
              <div className="input-item-half">
                <div className="mr-3 mt-half-1">
                  <label>
                    CPF<b className="obrigatory">*</b>
                  </label>
                  <Input
                    className="input"
                    type="text"
                    maxLength="14"
                    value={form.cpf}
                    placeholder="999.999.999-99"
                    onChange={(event) =>
                      setForm({ ...form, cpf: cpfMask(event.target.value) })
                    }
                  />
                  {invalidCpf && (
                    <div className="mt-1">
                      <span className="obrigatory mt">
                        O Cpf informado não é válido
                      </span>
                    </div>
                  )}
                </div>
                <div className="mt-half-1">
                  <label>
                    Estado<b className="obrigatory">*</b>
                  </label>
                  <Select
                    className="input"
                    options={states}
                    type="text"
                    placeholder="Selecione um estado"
                    value={form.state}
                    onChange={(event, value) =>
                      setForm({ ...form, state: value.value })
                    }
                  />
                </div>
              </div>
              <div className="input-item">
                <label>
                  Cidade<b className="obrigatory">*</b>
                </label>
                <Select
                  className="input"
                  options={citys}
                  type="text"
                  placeholder="Selecione uma cidade"
                  value={form.city}
                  onChange={(event, value) =>
                    setForm({ ...form, city: value.value })
                  }
                />
              </div>
              <div className="input-item">
                <label>
                  Nome do condomínio<b className="obrigatory">*</b>
                </label>
                <Input
                  className="input"
                  type="text"
                  placeholder="Vila das águas"
                  onChange={(event) =>
                    setForm({ ...form, condoName: event.target.value })
                  }
                />
              </div>
              <div className="input-item">
                <label>
                  E-mail<b className="obrigatory">*</b>
                </label>
                <Input
                  className="input"
                  type="email"
                  placeholder="josedasilva@mail.com"
                  onChange={(event) =>
                    setForm({ ...form, email: event.target.value })
                  }
                />
                {invalidEmail && (
                  <div className="mt-1">
                    <span className="obrigatory mt">
                      O Email informado não é válido
                    </span>
                  </div>
                )}
              </div>
              <div className="input-item">
                <label>
                  Confirme o E-mail<b className="obrigatory">*</b>
                </label>
                <Input
                  className="input"
                  type="email"
                  placeholder="josedasilva@mail.com"
                  onChange={(event) => setConfirmEmail(event.target.value)}
                />
                {isEqualEmail && (
                  <div className="mt-1">
                    <span className="obrigatory mt">
                      Os Emails não são iguais
                    </span>
                  </div>
                )}
              </div>
              {/* <div className="input-item">
              <label>
                Senha<b className="obrigatory">*</b>
              </label>
              <Input
                className="input"
                type="password"
                onChange={(event) =>
                  setForm({ ...form, password: event.target.value })
                }
              />
            </div>
            <div className="input-item">
              <label>
                Confirme a Senha<b className="obrigatory">*</b>
              </label>
              <Input
                className="input"
                type="password"
                onChange={(event) => setConfirmPassword(event.target.value)}
              />
              {isEqualPassword && (
                <div className="mt-1">
                  <span className="obrigatory mt">
                    As senhas não são iguais
                  </span>
                </div>
              )}
            </div> */}
              <div className="input-item checkbox-container">
                <input
                  type="checkbox"
                  className="checkbox"
                  onChange={(event) =>
                    setForm({ ...form, accept: event.target.checked })
                  }
                />
                <span>
                  Aceito receber conteúdos promocionais e ofertas do Severino
                  App
                  <b className="obrigatory">*</b>
                </span>
              </div>

              <div className="input-item checkbox-container">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_SITE_KEY}
                  onChange={(value) => setForm({ ...form, token: value })}
                />
              </div>

              <div className="button-container">
                <Button
                  disabled={isDisabled}
                  className="ui fluid button"
                  content="Criar Condomínio"
                  size="large"
                  primary
                  type="submit"
                />
              </div>
            </form>
          </main>
        </section>
        <section className="container-img">
          <div className="title-acess">
            <h2>
              Experimente o Severino App gratuitamente e veja como nosso sistema
              pode facilitar a administração do seu condomínio. Cadastre-se para
              um período trial e descubra a simplicidade e eficiência que
              oferecemos. Estamos aqui para ajudar a tornar a gestão do seu
              condomínio mais prática e tranquila.
            </h2>
          </div>
        </section>
      </div>
    </>
  );
}
