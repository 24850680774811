import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Auth, PrivateRoutes, PrivateRoute } from '../components';
import Login from './login';
import Buildings from './buildings/list';
import NewBuildings from './buildings/new';
import BuildingsDetails from './buildings/details';
import axios from '../config/axios';
import { Trial } from './trial';

const App = () => {
  const token = localStorage.getItem('token');
  const authenticated = !!token;
  const [auth, setAuth] = useState({ authenticated });
  const logout = () => {
    setAuth({ authenticated: false });
    localStorage.clear();
  };

  if (authenticated) axios.defaults.headers.common['Authorization'] = token;

  return (
    <Auth.Provider value={auth}>
      <Router>
        <Switch>
          <Route exact path='/login'>
            <Login setAuth={setAuth} />
          </Route>
          <Route path='/create-trial'>
            <Trial/>
          </Route>
          <PrivateRoutes path='/' logout={logout}>
            <PrivateRoute exact path='/'>
              <Buildings />
            </PrivateRoute>
            <PrivateRoute exact path='/buildings'>
              <Buildings />
            </PrivateRoute>
            <PrivateRoute exact path='/buildings/new'>
              <NewBuildings />
            </PrivateRoute>
            <PrivateRoute exact path='/buildings/:id'>
              <BuildingsDetails />
            </PrivateRoute>
          </PrivateRoutes>   
        </Switch>
      </Router>
    </Auth.Provider>
  );
};

export default App;
