import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Form as SemanticForm, Checkbox } from "semantic-ui-react";
import * as Yup from "yup";
import { Form, Dropdown } from "../../../../../components";

import "./styles.css";

const none = "NONE";
const custom = "CUSTOM";
const basic = "BASIC";
const plus = "PLUS";
const plans = [none, custom, basic, plus];
const schema = Yup.object().shape({
  plan: Yup.string().oneOf(plans).required(),
});

const Modules = ({
  building,
  changeBuildingPlan,
  activateOrInactivateBuildingModule,
}) => {
  const { t } = useTranslation();
  const plansOptions = plans.map((item) => ({
    key: item,
    value: item,
    text: t(`BUILDINGS.DETAILS.PLAN.PLANS.${item}`),
  }));
  const changeBuildingManagementPlan = (_, { value: plan }) => {
    changeBuildingPlan({ ...building.configuration.plans, management: plan });
  };
  const changeBuildingAccessControlPlan = (_, { value: plan }) => {
    changeBuildingPlan({
      ...building.configuration.plans,
      accessControl: plan,
    });
  };

  return (
    <>
      <Form initialData={building} schema={schema}>
        <SemanticForm.Group widths="equal">
          <Dropdown
            fluid
            selection
            name="configuration.plans.management"
            label={t("BUILDINGS.DETAILS.PLAN.PLAN_TYPES.MANAGEMENT")}
            placeholder={t("BUILDINGS.DETAILS.PLAN.PLAN_TYPES.MANAGEMENT")}
            options={plansOptions}
            onChange={changeBuildingManagementPlan}
          />
          <Dropdown
            fluid
            selection
            name="configuration.plans.accessControl"
            label={t("BUILDINGS.DETAILS.PLAN.PLAN_TYPES.ACCESS_CONTROL")}
            placeholder={t("BUILDINGS.DETAILS.PLAN.PLAN_TYPES.ACCESS_CONTROL")}
            options={plansOptions}
            onChange={changeBuildingAccessControlPlan}
          />
        </SemanticForm.Group>
        <SemanticForm.Field>
          <Grid>
            <Grid.Row columns={2}>
              {[custom, basic, plus].includes(
                building.configuration.plans.management
              ) && (
                <Grid.Column>
                  <label>
                    {t("BUILDINGS.DETAILS.PLAN.PLAN_TYPES.MANAGEMENT")}{" "}
                    {t("BUILDINGS.DETAILS.PLAN.PLANS.BASIC")}
                  </label>
                  <SemanticForm.Field className="module">
                    <Checkbox
                      toggle
                      label={t("BUILDINGS.DETAILS.PLAN.MODULES.ANNOUNCEMENTS")}
                      checked={
                        building.configuration.modules?.announcements?.enabled
                      }
                      onClick={activateOrInactivateBuildingModule(
                        "configuration.modules.announcements.enabled"
                      )}
                    />
                  </SemanticForm.Field>
                  <SemanticForm.Field className="module">
                    <Checkbox
                      toggle
                      label={t("BUILDINGS.DETAILS.PLAN.MODULES.TICKETS")}
                      checked={building.configuration.modules?.tickets?.enabled}
                      onClick={activateOrInactivateBuildingModule(
                        "configuration.modules.tickets.enabled"
                      )}
                    />
                  </SemanticForm.Field>
                  <SemanticForm.Field className="module">
                    <Checkbox
                      toggle
                      label={t("BUILDINGS.DETAILS.PLAN.MODULES.DOCUMENTS")}
                      checked={
                        building.configuration.modules?.documents?.enabled
                      }
                      onClick={activateOrInactivateBuildingModule(
                        "configuration.modules.documents.enabled"
                      )}
                    />
                  </SemanticForm.Field>
                  <SemanticForm.Field className="module">
                    <Checkbox
                      toggle
                      label={t("BUILDINGS.DETAILS.PLAN.MODULES.SUPERLOGICA")}
                      checked={
                        building.configuration.modules?.superlogica?.enabled
                      }
                      onClick={activateOrInactivateBuildingModule(
                        "configuration.modules.superlogica.enabled"
                      )}
                    />
                  </SemanticForm.Field>
                  <SemanticForm.Field className="module">
                    <Checkbox
                      toggle
                      label={t("BUILDINGS.DETAILS.PLAN.MODULES.GOSOFT")}
                      checked={building.configuration.modules?.gosoft?.enabled}
                      onClick={activateOrInactivateBuildingModule(
                        "configuration.modules.gosoft.enabled"
                      )}
                    />
                  </SemanticForm.Field>
                  <SemanticForm.Field className="module">
                    <Checkbox
                      toggle
                      label={t("BUILDINGS.DETAILS.PLAN.MODULES.GESTART")}
                      checked={building.configuration.modules?.gestart?.enabled}
                      onClick={activateOrInactivateBuildingModule(
                        "configuration.modules.gestart.enabled"
                      )}
                    />
                  </SemanticForm.Field>
                  <SemanticForm.Field className="module">
                    <Checkbox
                      toggle
                      label={t("BUILDINGS.DETAILS.PLAN.MODULES.PROSINDICO")}
                      checked={
                        building.configuration.modules?.prosindico?.enabled
                      }
                      onClick={activateOrInactivateBuildingModule(
                        "configuration.modules.prosindico.enabled"
                      )}
                    />
                  </SemanticForm.Field>
                  <SemanticForm.Field className="module">
                    <Checkbox
                      toggle
                      label={t("BUILDINGS.DETAILS.PLAN.MODULES.VERDE_ACQUA")}
                      checked={
                        building.configuration.modules?.verdeAcqua?.enabled
                      }
                      onClick={activateOrInactivateBuildingModule(
                        "configuration.modules.verdeAcqua.enabled"
                      )}
                    />
                  </SemanticForm.Field>
                  <SemanticForm.Field className="module">
                    <Checkbox
                      toggle
                      label={t("BUILDINGS.DETAILS.PLAN.MODULES.POLLS")}
                      checked={building.configuration.modules?.polls?.enabled}
                      onClick={activateOrInactivateBuildingModule(
                        "configuration.modules.polls.enabled"
                      )}
                    />
                  </SemanticForm.Field>
                  <SemanticForm.Field className="module">
                    <Checkbox
                      toggle
                      label={t("BUILDINGS.DETAILS.PLAN.MODULES.PHONES")}
                      checked={building.configuration.modules?.phones?.enabled}
                      onClick={activateOrInactivateBuildingModule(
                        "configuration.modules.phones.enabled"
                      )}
                    />
                  </SemanticForm.Field>
                </Grid.Column>
              )}
              {[custom, plus].includes(
                building.configuration.plans.management
              ) && (
                <Grid.Column>
                  <Grid.Row>
                    <label>
                      {t("BUILDINGS.DETAILS.PLAN.PLAN_TYPES.MANAGEMENT")}{" "}
                      {t("BUILDINGS.DETAILS.PLAN.PLANS.PLUS")}
                    </label>
                    <SemanticForm.Field className="module">
                      <Checkbox
                        toggle
                        label={t("BUILDINGS.DETAILS.PLAN.MODULES.BOOKINGS")}
                        checked={
                          building.configuration.modules?.bookings?.enabled
                        }
                        onClick={activateOrInactivateBuildingModule(
                          "configuration.modules.bookings.enabled"
                        )}
                      />
                    </SemanticForm.Field>
                    <SemanticForm.Field className="module">
                      <Checkbox
                        toggle
                        label={t(
                          "BUILDINGS.DETAILS.PLAN.MODULES.WATER_MEASUREMENTS"
                        )}
                        checked={
                          building.configuration.modules?.waterMeasurements
                            ?.enabled
                        }
                        onClick={activateOrInactivateBuildingModule(
                          "configuration.modules.waterMeasurements.enabled"
                        )}
                      />
                    </SemanticForm.Field>
                    <SemanticForm.Field className="module">
                      <Checkbox
                        toggle
                        label={t(
                          "BUILDINGS.DETAILS.PLAN.MODULES.GAS_MEASUREMENTS"
                        )}
                        checked={
                          building.configuration.modules?.gasMeasurements
                            ?.enabled
                        }
                        onClick={activateOrInactivateBuildingModule(
                          "configuration.modules.gasMeasurements.enabled"
                        )}
                      />
                    </SemanticForm.Field>
                    <SemanticForm.Field className="module">
                      <Checkbox
                        toggle
                        label={t(
                          "BUILDINGS.DETAILS.PLAN.MODULES.POWER_MEASUREMENTS"
                        )}
                        checked={
                          building.configuration.modules?.powerMeasurements
                            ?.enabled
                        }
                        onClick={activateOrInactivateBuildingModule(
                          "configuration.modules.powerMeasurements.enabled"
                        )}
                      />
                    </SemanticForm.Field>
                    <SemanticForm.Field className="module">
                      <Checkbox
                        toggle
                        label={t(
                          "BUILDINGS.DETAILS.PLAN.MODULES.OBJECTS_CONTROL"
                        )}
                        checked={
                          building.configuration.modules?.objectsControl
                            ?.enabled
                        }
                        onClick={activateOrInactivateBuildingModule(
                          "configuration.modules.objectsControl.enabled"
                        )}
                      />
                    </SemanticForm.Field>
                    <SemanticForm.Field className="module">
                      <Checkbox
                        toggle
                        label={t("BUILDINGS.DETAILS.PLAN.MODULES.CLASSIFIEDS")}
                        checked={
                          building.configuration.modules?.classifieds?.enabled
                        }
                        onClick={activateOrInactivateBuildingModule(
                          "configuration.modules.classifieds.enabled"
                        )}
                      />
                    </SemanticForm.Field>
                    <SemanticForm.Field className="module">
                      <Checkbox
                        toggle
                        label={t(
                          "BUILDINGS.DETAILS.PLAN.MODULES.PEOPLE_ON_BOARD"
                        )}
                        checked={
                          building.configuration.modules?.peopleOnBoard?.enabled
                        }
                        onClick={activateOrInactivateBuildingModule(
                          "configuration.modules.peopleOnBoard.enabled"
                        )}
                      />
                    </SemanticForm.Field>
                    <SemanticForm.Field className='module'>
                      <Checkbox
                        toggle
                        label={t('BUILDINGS.DETAILS.PLAN.MODULES.WARNINGS')}
                        checked={building.configuration.modules?.warnings?.enabled}
                        onClick={activateOrInactivateBuildingModule('configuration.modules.warnings.enabled')}
                        />
                    </SemanticForm.Field>
                  </Grid.Row>
                </Grid.Column>
              )}
            </Grid.Row>
            <Grid.Row columns={2}>
              {[custom, basic, plus].includes(
                building.configuration.plans.accessControl
              ) && (
                <Grid.Column>
                  <label>
                    {t("BUILDINGS.DETAILS.PLAN.PLAN_TYPES.ACCESS_CONTROL")}{" "}
                    {t("BUILDINGS.DETAILS.PLAN.PLANS.BASIC")}
                  </label>
                  <SemanticForm.Field className="module">
                    <Checkbox
                      toggle
                      label={t("BUILDINGS.DETAILS.PLAN.MODULES.ACCESS_CONTROL")}
                      checked={
                        building.configuration.modules?.accessControl?.enabled
                      }
                      onClick={activateOrInactivateBuildingModule(
                        "configuration.modules.accessControl.enabled"
                      )}
                    />
                  </SemanticForm.Field>
                  <SemanticForm.Field className="module">
                    <Checkbox
                      toggle
                      label={t("BUILDINGS.DETAILS.PLAN.MODULES.RENTS")}
                      checked={building.configuration.modules?.rents?.enabled}
                      onClick={activateOrInactivateBuildingModule(
                        "configuration.modules.rents.enabled"
                      )}
                    />
                  </SemanticForm.Field>
                  <SemanticForm.Field className="module">
                    <Checkbox
                      toggle
                      label={t("BUILDINGS.DETAILS.PLAN.MODULES.SHIFT_LOGS")}
                      checked={
                        building.configuration.modules?.shiftLogs?.enabled
                      }
                      onClick={activateOrInactivateBuildingModule(
                        "configuration.modules.shiftLogs.enabled"
                      )}
                    />
                  </SemanticForm.Field>
                  <SemanticForm.Field className="module">
                    <Checkbox
                      toggle
                      label={t("BUILDINGS.DETAILS.PLAN.MODULES.PACKAGES")}
                      checked={
                        building.configuration.modules?.packages?.enabled
                      }
                      onClick={activateOrInactivateBuildingModule(
                        "configuration.modules.packages.enabled"
                      )}
                    />
                  </SemanticForm.Field>
                </Grid.Column>
              )}
              {[custom, plus].includes(
                building.configuration.plans.accessControl
              ) && (
                <Grid.Column>
                  <label>
                    {t("BUILDINGS.DETAILS.PLAN.PLAN_TYPES.ACCESS_CONTROL")}{" "}
                    {t("BUILDINGS.DETAILS.PLAN.PLANS.PLUS")}
                  </label>
                  <SemanticForm.Field className="module">
                    <Checkbox
                      toggle
                      label={t("BUILDINGS.DETAILS.PLAN.MODULES.LINEAR")}
                      checked={building.configuration.modules?.linear?.enabled}
                      onClick={activateOrInactivateBuildingModule(
                        "configuration.modules.linear.enabled"
                      )}
                    />
                  </SemanticForm.Field>
                </Grid.Column>
              )}
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column>
                <label>Módulos Básicos</label>
                <SemanticForm.Field className="module">
                  <Checkbox
                    toggle
                    label="Pessoas"
                    checked={building.configuration.modules?.people?.enabled}
                    onClick={activateOrInactivateBuildingModule(
                      "configuration.modules.people.enabled"
                    )}
                  />
                </SemanticForm.Field>
                <SemanticForm.Field className="module">
                  <Checkbox
                    toggle
                    label="Veículos"
                    checked={building.configuration.modules?.vehicles?.enabled}
                    onClick={activateOrInactivateBuildingModule(
                      "configuration.modules.vehicles.enabled"
                    )}
                  />
                </SemanticForm.Field>
                <SemanticForm.Field className="module">
                  <Checkbox
                    toggle
                    label="Horário de Ponto"
                    checked={building.configuration.modules?.timeSlot?.enabled}
                    onClick={activateOrInactivateBuildingModule(
                      "configuration.modules.timeSlot.enabled"
                    )}
                  />
                </SemanticForm.Field>
                <SemanticForm.Field className="module">
                  <Checkbox
                    toggle
                    label="Unidades"
                    checked={building.configuration.modules?.units?.enabled}
                    onClick={activateOrInactivateBuildingModule(
                      "configuration.modules.units.enabled"
                    )}
                  />
                </SemanticForm.Field>
                <SemanticForm.Field className="module">
                  <Checkbox
                    toggle
                    label="Academy"
                    checked={building.configuration.modules?.academy?.enabled}
                    onClick={activateOrInactivateBuildingModule(
                      "configuration.modules.academy.enabled"
                    )}
                  />
                </SemanticForm.Field>
                <SemanticForm.Field className="module">
                  <Checkbox
                    toggle
                    label="Manuais"
                    checked={building.configuration.modules?.manuals?.enabled}
                    onClick={activateOrInactivateBuildingModule(
                      "configuration.modules.manuals.enabled"
                    )}
                  />
                </SemanticForm.Field>
              </Grid.Column>
              <Grid.Column>
                <label>{t("BUILDINGS.DETAILS.PLAN.PLAN_TYPES.EXTRAS")}</label>
                <SemanticForm.Field className="module">
                  <Checkbox
                    toggle
                    label={t("BUILDINGS.DETAILS.PLAN.MODULES.ASSEMBLIES")}
                    checked={
                      building.configuration.modules?.assemblies?.enabled
                    }
                    onClick={activateOrInactivateBuildingModule(
                      "configuration.modules.assemblies.enabled"
                    )}
                  />
                </SemanticForm.Field>
                <SemanticForm.Field className="module">
                  <Checkbox
                    toggle
                    label="OCR - Validar CNH"
                    checked={
                      building.configuration.modules?.ocrDocuments?.cnhValidate?.enabled
                    }
                    onClick={activateOrInactivateBuildingModule(
                      "configuration.modules.ocrDocuments.cnhValidate.enabled"
                    )}
                  />
                </SemanticForm.Field>
                <SemanticForm.Field className="module">
                  <Checkbox
                    toggle
                    label={t("BUILDINGS.DETAILS.PLAN.MODULES.GET_NINJAS")}
                    checked={
                      building.configuration.integrations?.getNinjas?.enabled
                    }
                    onClick={activateOrInactivateBuildingModule(
                      "configuration.integrations.getNinjas.enabled"
                    )}
                  />
                </SemanticForm.Field>
                <SemanticForm.Field className="module">
                  <Checkbox
                    toggle
                    label={t("BUILDINGS.DETAILS.PLAN.MODULES.MAINTENANCE_PLAN") + ' - Convencional'}
                    checked={
                      building.configuration.modules?.maintenance?.enabled
                    }
                    onClick={activateOrInactivateBuildingModule(
                      "configuration.modules.maintenance.enabled"
                    )}
                  />
                </SemanticForm.Field>
                <SemanticForm.Field className="module">
                  <Checkbox
                    toggle
                    label="Plano de Manutenção - Exclusivo"
                    checked={
                      building.configuration.modules?.maintenanceExclusive
                        ?.enabled
                    }
                    onClick={activateOrInactivateBuildingModule(
                      "configuration.modules.maintenanceExclusive.enabled"
                    )}
                  />
                </SemanticForm.Field>
                <SemanticForm.Field className="module">
                  <Checkbox
                    toggle
                    label="Plano de Manutenção - Notificações E-MAIL"
                    checked={
                      building.configuration.modules?.maintenance
                        ?.notifications.email
                    }
                    onClick={activateOrInactivateBuildingModule(
                      "configuration.modules.maintenance.notifications.email"
                    )}
                  />
                </SemanticForm.Field>
                <SemanticForm.Field className="module">
                  <Checkbox
                    toggle
                    label="Plano de Manutenção - Notificações WHATSAPP"
                    checked={
                      building.configuration.modules?.maintenance
                        ?.notifications.whatsapp
                    }
                    onClick={activateOrInactivateBuildingModule(
                      "configuration.modules.maintenance.notifications.whatsapp"
                    )}
                  />
                </SemanticForm.Field>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </SemanticForm.Field>
      </Form>
    </>
  );
};

export default Modules;
