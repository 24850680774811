import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Segment, Header, Grid } from 'semantic-ui-react';
import Steps from './components/steps';
import Contents from './components/contents';
import axios from '../../../config/axios';

import './styles.css';
import GoBack from '../../../components/go-back';

const NewBuilding = () => {
  const { t } = useTranslation();
  const [step, changeStep] = useState(0);
  const [companies, setCompanies] = useState([]);
  const [building, setBuilding] = useState({
    blocks: [],
    units: [],
    location: { address: {} },
    configuration: {
      plans: {
        management: 'BASIC',
        accessControl: 'BASIC'
      }
    }
  });
  const fetchCompanies = useCallback(async () => {
    const params = { active: true, $select: ['name', 'icon'] };
    const result = await axios.get('companies', { params });
    const companies = result.data.map(({ _id, name, icon }) => ({
      key: _id,
      text: name,
      value: _id,
      image: `https://api.severinoapp.com/icons/${icon}`
    }));

    setCompanies(companies);
  }, []);

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  return (
    <Container className='page'>
      <Segment.Group>
        <Segment className='building-new-title-segment'>
          <GoBack route="/" />
          <Header as='h2'>{t('BUILDINGS.NEW.TITLE')}</Header>
          <div></div>
        </Segment>
        <Segment>
          <Grid>
            <Grid.Column width={7}>
              <Steps step={step} />
            </Grid.Column>
            <Grid.Column width={9}>
              <Contents
                step={step}
                building={building}
                changeStep={changeStep}
                companies={companies}
                setBuilding={setBuilding}
              />
            </Grid.Column>
          </Grid>
        </Segment>
      </Segment.Group>
    </Container>
  );
};

export default NewBuilding;
