import React from "react";
import { useTranslation } from "react-i18next";
import {
  Step,
  Form as SemanticForm,
  Icon,
  Grid,
  Button,
} from "semantic-ui-react";
import { Form, Checkbox, Dropdown } from "../../../../../components";
import "./styles.css";

const none = "NONE";
const custom = "CUSTOM";
const basic = "BASIC";
const plus = "PLUS";
const plans = [none, custom, basic, plus];

const PlanStep = ({ active, completed }) => {
  const { t } = useTranslation();

  return (
    <Step active={active} completed={completed}>
      <Icon name="grid layout" />
      <Step.Content>
        <Step.Title>{t("BUILDINGS.NEW.PLAN_STEP.TITLE")}</Step.Title>
        <Step.Description>
          {t("BUILDINGS.NEW.PLAN_STEP.DESCRIPTION")}
        </Step.Description>
      </Step.Content>
    </Step>
  );
};

const PlanContent = ({ active, building, setBuilding, prev, next }) => {
  const { t } = useTranslation();
  const plansOptions = plans.map((item) => ({
    key: item,
    value: item,
    text: t(`BUILDINGS.NEW.PLAN_STEP.PLANS.${item}`),
  }));
  const changeBuildingManagementPlan = (_, { value: plan }) => {
    const plans = { ...building.configuration.plans, management: plan };
    const configuration = { ...building.configuration, plans };

    setBuilding({ ...building, configuration });
  };
  const changeBuildingAccessControlPlan = (_, { value: plan }) => {
    const plans = { ...building.configuration.plans, accessControl: plan };
    const configuration = { ...building.configuration, plans };

    setBuilding({ ...building, configuration });
  };

  return (
    active && (
      <Form initialData={building} onSubmit={next}>
        <SemanticForm.Group widths="equal">
          <Dropdown
            fluid
            selection
            name="configuration.plans.management"
            label={t("BUILDINGS.NEW.PLAN_STEP.PLAN_TYPES.MANAGEMENT")}
            placeholder={t("BUILDINGS.NEW.PLAN_STEP.PLAN_TYPES.MANAGEMENT")}
            options={plansOptions}
            onChange={changeBuildingManagementPlan}
          />
          <Dropdown
            fluid
            selection
            name="configuration.plans.accessControl"
            label={t("BUILDINGS.NEW.PLAN_STEP.PLAN_TYPES.ACCESS_CONTROL")}
            placeholder={t("BUILDINGS.NEW.PLAN_STEP.PLAN_TYPES.ACCESS_CONTROL")}
            options={plansOptions}
            onChange={changeBuildingAccessControlPlan}
          />
        </SemanticForm.Group>
        <Grid>
          <Grid.Row columns={2}>
            {[custom, basic, plus].includes(
              building.configuration.plans.management
            ) && (
              <Grid.Column>
                <label>
                  {t("BUILDINGS.NEW.PLAN_STEP.PLAN_TYPES.MANAGEMENT")}{" "}
                  {t("BUILDINGS.DETAILS.PLAN.PLANS.BASIC")}
                </label>
                <Checkbox
                  toggle
                  name="modules.announcements.enabled"
                  label={t("BUILDINGS.NEW.PLAN_STEP.MODULES.ANNOUNCEMENTS")}
                />
                <Checkbox
                  toggle
                  name="modules.tickets.enabled"
                  label={t("BUILDINGS.NEW.PLAN_STEP.MODULES.TICKETS")}
                />
                <Checkbox
                  toggle
                  name="modules.documents.enabled"
                  label={t("BUILDINGS.NEW.PLAN_STEP.MODULES.DOCUMENTS")}
                />
                <Checkbox
                  toggle
                  name="modules.superlogica.enabled"
                  label={t("BUILDINGS.NEW.PLAN_STEP.MODULES.SUPERLOGICA")}
                />
                <Checkbox
                  toggle
                  name="modules.gosoft.enabled"
                  label={t("BUILDINGS.NEW.PLAN_STEP.MODULES.GOSOFT")}
                />
                <Checkbox
                  toggle
                  name="modules.verdeAcqua.enabled"
                  label={t("BUILDINGS.NEW.PLAN_STEP.MODULES.VERDE_ACQUA")}
                />
                <Checkbox
                  toggle
                  name="modules.polls.enabled"
                  label={t("BUILDINGS.NEW.PLAN_STEP.MODULES.POLLS")}
                />
                <Checkbox
                  toggle
                  name="modules.phones.enabled"
                  label={t("BUILDINGS.NEW.PLAN_STEP.MODULES.PHONES")}
                />
              </Grid.Column>
            )}
            {[custom, plus].includes(
              building.configuration.plans.management
            ) && (
              <Grid.Column>
                <label>
                  {t("BUILDINGS.NEW.PLAN_STEP.PLAN_TYPES.MANAGEMENT")}{" "}
                  {t("BUILDINGS.DETAILS.PLAN.PLANS.PLUS")}
                </label>
                <Checkbox
                  toggle
                  name="modules.bookings.enabled"
                  label={t("BUILDINGS.NEW.PLAN_STEP.MODULES.BOOKINGS")}
                />
                <Checkbox
                  toggle
                  name="modules.waterMeasurements.enabled"
                  label={t(
                    "BUILDINGS.NEW.PLAN_STEP.MODULES.WATER_MEASUREMENTS"
                  )}
                />
                <Checkbox
                  toggle
                  name="modules.gasMeasurements.enabled"
                  label={t("BUILDINGS.NEW.PLAN_STEP.MODULES.GAS_MEASUREMENTS")}
                />
                <Checkbox
                  toggle
                  name="modules.powerMeasurements.enabled"
                  label={t(
                    "BUILDINGS.NEW.PLAN_STEP.MODULES.POWER_MEASUREMENTS"
                  )}
                />
                <Checkbox
                  toggle
                  name="modules.objectsControl.enabled"
                  label={t("BUILDINGS.NEW.PLAN_STEP.MODULES.OBJECTS_CONTROL")}
                />
                <Checkbox
                  toggle
                  name="modules.classifieds.enabled"
                  label={t("BUILDINGS.NEW.PLAN_STEP.MODULES.CLASSIFIEDS")}
                />
                <Checkbox
                  toggle
                  name="modules.peopleOnBoard.enabled"
                  label={t("BUILDINGS.NEW.PLAN_STEP.MODULES.PEOPLE_ON_BOARD")}
                />
                <Checkbox
                  toggle
                  name='modules.warnings.enabled'
                  label={t('BUILDINGS.DETAILS.PLAN.MODULES.WARNINGS')}
                />
              </Grid.Column>
            )}
          </Grid.Row>
          <Grid.Row columns={2}>
            {[custom, basic, plus].includes(
              building.configuration.plans.accessControl
            ) && (
              <Grid.Column>
                <label>
                  {t("BUILDINGS.NEW.PLAN_STEP.PLAN_TYPES.ACCESS_CONTROL")}{" "}
                  {t("BUILDINGS.DETAILS.PLAN.PLANS.BASIC")}
                </label>
                <Checkbox
                  toggle
                  name="modules.accessControl.enabled"
                  label={t("BUILDINGS.NEW.PLAN_STEP.MODULES.ACCESS_CONTROL")}
                />
                <Checkbox
                  toggle
                  name="modules.rents.enabled"
                  label={t("BUILDINGS.NEW.PLAN_STEP.MODULES.RENTS")}
                />
                <Checkbox
                  toggle
                  name="modules.shiftLogs.enabled"
                  label={t("BUILDINGS.NEW.PLAN_STEP.MODULES.SHIFT_LOGS")}
                />
              </Grid.Column>
            )}
            {[custom, plus].includes(
              building.configuration.plans.accessControl
            ) && (
              <Grid.Column>
                <label>
                  {t("BUILDINGS.NEW.PLAN_STEP.PLAN_TYPES.ACCESS_CONTROL")}{" "}
                  {t("BUILDINGS.DETAILS.PLAN.PLANS.PLUS")}
                </label>
                <Checkbox
                  toggle
                  name="modules.linear.enabled"
                  label={t("BUILDINGS.NEW.PLAN_STEP.MODULES.LINEAR")}
                />
              </Grid.Column>
            )}
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>
              <label>Módulos Básicos</label>
              <Checkbox toggle label="Pessoas" name="modules.people.enabled" />
              <Checkbox
                toggle
                label="Veículos"
                name="modules.vehicles.enabled"
              />
              <Checkbox
                toggle
                label="Horário de Ponto"
                name="modules.timeSlot.enabled"
              />
              <Checkbox toggle label="Unidades" name="modules.units.enabled" />
              <Checkbox toggle label="Academy" name="modules.academy.enabled" />
              <Checkbox toggle label="Manuais" name="modules.manuals.enabled" />
            </Grid.Column>
            <Grid.Column>
              <label>{t("BUILDINGS.NEW.PLAN_STEP.PLAN_TYPES.EXTRAS")}</label>
              <Checkbox
                toggle
                name="modules.assemblies.enabled"
                label={t("BUILDINGS.NEW.PLAN_STEP.MODULES.ASSEMBLIES")}
              />
              <Checkbox
                toggle
                label="OCR - Validar CNH"
                name="modules.ocrDocuments.cnhValidate.enabled"
              />
              <Checkbox
                toggle
                name="modules.maintenance.enabled"
                label={t("BUILDINGS.NEW.PLAN_STEP.MODULES.MAINTENANCE_PLAN") + ' - Convencional'}
              />
              <Checkbox
                toggle
                name="modules.maintenanceExclusive.enabled"
                label="Plano de Manutenção - Exclusivo"
              />
              <Checkbox
                toggle
                name="modules.maintenance.notifications.email"
                label="Plano de Manutenção - Notificações EMAIL"
              />
              <Checkbox
                toggle
                name="modules.maintenance.notifications.whatsapp"
                label="Plano de Manutenção - Notificações WHATSAPP"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <SemanticForm.Group widths="equal" className="mt-2">
          <Button primary onClick={prev}>
            {t("BUILDINGS.NEW.PREV")}
          </Button>
          <Button primary type="submit">
            {t("BUILDINGS.NEW.NEXT")}
          </Button>
        </SemanticForm.Group>
      </Form>
    )
  );
};

export { PlanStep, PlanContent };
