import React from 'react';
import { Segment, Button } from 'semantic-ui-react';

const RelatedActions = ({ showUnitModal }) => (
  <Segment>
    <Button type='button' icon='plus' onClick={showUnitModal} />
  </Segment>
);

export default RelatedActions;
