import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Step, Icon, Button } from 'semantic-ui-react';
import { Form, Dropdown } from '../../../../../components';

const schema = Yup.object().shape({ company: Yup.string().required() });

const CompanyStep = ({ active, completed }) => {
  const { t } = useTranslation();

  return (
    <Step active={active} completed={completed}>
      <Icon name='industry' />
      <Step.Content>
        <Step.Title>{t('BUILDINGS.NEW.COMPANY_STEP.TITLE')}</Step.Title>
        <Step.Description>{t('BUILDINGS.NEW.COMPANY_STEP.DESCRIPTION')}</Step.Description>
      </Step.Content>
    </Step>
  );
};

const CompanyContent = ({ active, building, companies, next }) => {
  const { t } = useTranslation();

  return active && (
    <Form initialData={building} onSubmit={next} schema={schema}>
      <Dropdown
        fluid
        selection
        name='company'
        label={t('BUILDINGS.NEW.COMPANY_STEP.COMPANY.LABEL')}
        placeholder={t('BUILDINGS.NEW.COMPANY_STEP.COMPANY.PLACEHOLDER')}
        options={companies}
      />
      <Button primary type='submit'>{t('BUILDINGS.NEW.NEXT')}</Button>
    </Form>
  );
};

export { CompanyStep, CompanyContent };
