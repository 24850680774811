import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'semantic-ui-react';
import { Form, Input } from '../../../../../components';

const schema = Yup.object().shape({
  name: Yup.string().required()
});

const BlockModal = ({ block, save, opened, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal size='mini' open={opened} onClose={onClose}>
      <Modal.Header>{t('BUILDINGS.NEW.STRUCTURE_STEP.BLOCK_MODAL.TITLE')}</Modal.Header>
      <Modal.Content>
        <Form id='block-form' initialData={block} onSubmit={save} schema={schema}>
          <Input
            name='name'
            label={t('BUILDINGS.NEW.STRUCTURE_STEP.BLOCK_MODAL.NAME.LABEL')}
            placeholder={t('BUILDINGS.NEW.STRUCTURE_STEP.BLOCK_MODAL.NAME.PLACEHOLDER')}
          />
          <Input hidden name='id' />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={onClose}>
          {t('BUILDINGS.NEW.STRUCTURE_STEP.BLOCK_MODAL.CANCEL')}
        </Button>
        <Button
          positive
          form='block-form'
          icon='checkmark'
          labelPosition='right'
          content={t('BUILDINGS.NEW.STRUCTURE_STEP.BLOCK_MODAL.SAVE')}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default BlockModal;
