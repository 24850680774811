import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Step, Icon, Form as SemanticForm, Button } from 'semantic-ui-react';
import { Form, Dropdown, Input } from '../../../../../components';
import i18n from '../../../../../config/i18n';

const toOptions = item => ({
  key: item,
  value: item,
  text: i18n.t(`GLOBAL.LABELS.${item}`)
});
const orientations = ['HORIZONTAL', 'VERTICAL'];
const orientationsOptions = orientations.map(toOptions);
const blockLabels = ['BLOCK', 'TOWER', 'QUATRAIN', 'STREET'];
const blockLabelsOptions = blockLabels.map(toOptions);
const unitLabels = ['UNIT', 'APARTMENT', 'HOUSE', 'LOT', 'COMMERCIAL_ROOM', 'HOTEL_ROOM', 'BUNGALOW', 'SECTOR', 'DEPARTAMENT'];
const unitLabelsOptions = unitLabels.map(toOptions);
const nameRules = ['LETTERS', 'NUMBERS'];
const nameRulesOptions = nameRules.map(toOptions);
const userLabels = ['DWELLER', 'USER', 'COLLABORATOR', 'SALISTA', 'EMPLOYEE']
const userLabelsOptions = userLabels.map(toOptions)
const generateId = () => Math.random().toString(36).substring(2) + Date.now().toString(36);
const createName = (rule, index) => rule === 'LETTERS'
  ? String.fromCharCode(97 + index).toUpperCase()
  : `${index + 1}`;
const createUnitIdentifier = (rule, floor, index) => rule === 'LETTERS'
  ? String.fromCharCode(97 + index).toUpperCase()
  : `${Number(floor) || ''}${floor + 1 && index <= 8 ? '0' : ''}${index + 1}`;

const ConfigurationStep = ({ active, completed }) => {
  const { t } = useTranslation();

  return (
    <Step active={active} completed={completed}>
      <Icon name='setting' />
      <Step.Content>
        <Step.Title>{t('BUILDINGS.NEW.CONFIGURATION_STEP.TITLE')}</Step.Title>
        <Step.Description>
          {t('BUILDINGS.NEW.CONFIGURATION_STEP.DESCRIPTION')}
        </Step.Description>
      </Step.Content>
    </Step>
  );
};

const ConfigurationContent = ({ active, building, prev, next }) => {
  const { t } = useTranslation();
  const [orientation, setOrientation] = useState('');
  const onChangeOrientation = (_, { value }) => setOrientation(value);
  const unitsAmountLabel = orientation === 'VERTICAL'
    ? t('BUILDINGS.NEW.CONFIGURATION_STEP.UNITS_PER_FLOOR.LABEL')
    : t('BUILDINGS.NEW.CONFIGURATION_STEP.UNITS.LABEL');
  const unitsAmountPlaceholder = orientation === 'VERTICAL'
    ? t('BUILDINGS.NEW.CONFIGURATION_STEP.UNITS_PER_FLOOR.PLACEHOLDER')
    : t('BUILDINGS.NEW.CONFIGURATION_STEP.UNITS.PLACEHOLDER');
  const schema = Yup.object().shape({
    orientation: Yup.string().oneOf(orientations).required(),
    blockLabel: Yup.string().oneOf(blockLabels).required(),
    unitLabel: Yup.string().oneOf(unitLabels).required(),
    blocksAmount: Yup.number().required(),
    ...(orientation === 'VERTICAL' && { floorsAmount: Yup.number().required() }),
    unitsAmount: Yup.number().required(),
    blockNameRule: Yup.string().oneOf(nameRules).required(),
    unitNameRule: Yup.string().oneOf(nameRules).required(), 
    userNameLabel: Yup.string().oneOf(userLabels).required()
  });
  const submit = data => {
    const {
      orientation,
      blockLabel,
      unitLabel,
      blocksAmount,
      floorsAmount,
      unitsAmount,
      blockNameRule,
      unitNameRule,
      userNameLabel
    } = data;
    const blocksInterator = Array(Number(blocksAmount)).fill('');
    const blocks = blocksInterator.map((_, index) => ({
      id: generateId(),
      name: createName(blockNameRule, index)
    }));
    const createUnits = (relateds, relatedAttribute) => {
      const unitsInterator = Array(Number(unitsAmount)).fill('');
      const units = relateds.reduce((prev, related) => {
        const relatedName = orientation === 'VERTICAL' ? related.name : undefined;
        const relatedUnits = unitsInterator.map((_, index) => ({
          id: generateId(),
          [relatedAttribute]: related.id,
          identifier: createUnitIdentifier(unitNameRule, relatedName, index)
        }));

        return [...prev, ...relatedUnits];
      }, []);

      return units;
    };

    if (orientation === 'VERTICAL') {
      const floorsInterator = Array(Number(floorsAmount)).fill('');
      const floors = blocks.reduce((prev, block) => {
        const blockFloors = floorsInterator.map((_, index) => ({
          id: generateId(),
          block: block.id,
          name: createName('NUMBERS', index)
        }));

        return [...prev, ...blockFloors];
      }, []);
      const units = createUnits(floors, 'floor');

      return next({
        ...building,
        ...data,
        orientation,
        blockLabel,
        unitLabel,
        blocks,
        floors,
        units,
        userNameLabel
      });
    }

    const units = createUnits(blocks, 'block');

    next({
      ...building,
      ...data,
      orientation,
      blockLabel,
      unitLabel,
      blocks,
      units,
      userNameLabel
    });
  };

  return active && (
    <Form initialData={building} onSubmit={submit} schema={schema}>
      <Dropdown
        fluid
        selection
        name='orientation'
        label={t('BUILDINGS.NEW.CONFIGURATION_STEP.ORIENTATION.LABEL')}
        placeholder={t('BUILDINGS.NEW.CONFIGURATION_STEP.ORIENTATION.PLACEHOLDER')}
        options={orientationsOptions}
        onChange={onChangeOrientation}
      />
      <SemanticForm.Group widths='equal'>
        <Dropdown
          fluid
          selection
          name='blockLabel'
          label={t('BUILDINGS.NEW.CONFIGURATION_STEP.BLOCK_LABEL.LABEL')}
          placeholder={t('BUILDINGS.NEW.CONFIGURATION_STEP.BLOCK_LABEL.PLACEHOLDER')}
          options={blockLabelsOptions}
        />
        <Dropdown
          fluid
          selection
          name='unitLabel'
          label={t('BUILDINGS.NEW.CONFIGURATION_STEP.UNIT_LABEL.LABEL')}
          placeholder={t('BUILDINGS.NEW.CONFIGURATION_STEP.UNIT_LABEL.PLACEHOLDER')}
          options={unitLabelsOptions}
        />
      </SemanticForm.Group>
      <SemanticForm.Group widths='equal'>
        <Input
          type='number'
          name='blocksAmount'
          label={t('BUILDINGS.NEW.CONFIGURATION_STEP.BLOCKS.LABEL')}
          placeholder={t('BUILDINGS.NEW.CONFIGURATION_STEP.BLOCKS.PLACEHOLDER')}
        />
        {orientation === 'VERTICAL' && (
          <Input
            type='number'
            name='floorsAmount'
            label={t('BUILDINGS.NEW.CONFIGURATION_STEP.FLOORS.LABEL')}
            placeholder={t('BUILDINGS.NEW.CONFIGURATION_STEP.FLOORS.PLACEHOLDER')}
          />
        )}
        <Input
          type='number'
          name='unitsAmount'
          label={unitsAmountLabel}
          placeholder={unitsAmountPlaceholder}
        />
      </SemanticForm.Group>
      <SemanticForm.Group widths='equal'>
        <Dropdown
          fluid
          selection
          name='blockNameRule'
          label={t('BUILDINGS.NEW.CONFIGURATION_STEP.BLOCK_NAME_RULE.LABEL')}
          placeholder={t('BUILDINGS.NEW.CONFIGURATION_STEP.BLOCK_NAME_RULE.PLACEHOLDER')}
          options={nameRulesOptions}
        />
        <Dropdown
          fluid
          selection
          name='unitNameRule'
          label={t('BUILDINGS.NEW.CONFIGURATION_STEP.UNIT_NAME_RULE.LABEL')}
          placeholder={t('BUILDINGS.NEW.CONFIGURATION_STEP.UNIT_NAME_RULE.PLACEHOLDER')}
          options={nameRulesOptions}
        />
      </SemanticForm.Group>
      <SemanticForm.Group widths='equal'>
      <Dropdown
          fluid
          selection
          name='userNameLabel'
          label={t('BUILDINGS.NEW.CONFIGURATION_STEP.USER_TYPE_RULE.LABEL')}
          placeholder={t('BUILDINGS.NEW.CONFIGURATION_STEP.USER_TYPE_RULE.PLACEHOLDER')}
          options={userLabelsOptions}
        />
      </SemanticForm.Group>
      <Button primary onClick={prev}>{t('BUILDINGS.NEW.PREV')}</Button>
      <Button primary type='submit'>{t('BUILDINGS.NEW.NEXT')}</Button>
    </Form>
  );
};

export { ConfigurationStep, ConfigurationContent };
