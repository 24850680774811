import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Auth from '../auth';

const PrivateRoute = ({ children, ...rest }) => {
  const auth = useContext(Auth);

  return (
    <Route
      {...rest}
      render={({ location }) => auth.authenticated ? (
        children
      ) : (
          <Redirect to={{ pathname: '/login', state: { from: location } }} />
        )}
    />
  );
};

export default PrivateRoute;
