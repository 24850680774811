import React from 'react';
import { useTranslation } from 'react-i18next';
import { Segment, Header } from 'semantic-ui-react';

const Floors = ({ children, building, block }) => {
  const { t } = useTranslation();

  return building.floors.filter(floor => floor.block === block.id).map(floor => (
    <Segment.Group key={floor.id}>
      <Segment>
        <Header as='h5'>
          {t('BUILDINGS.NEW.STRUCTURE_STEP.FLOOR')} {floor.name}
        </Header>
      </Segment>
      {children(floor)}
    </Segment.Group>
  ));
};

export default Floors;
