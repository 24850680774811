import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { listTimeZones } from 'timezone-support';
import { Step, Icon, Form as SemanticForm, Button } from 'semantic-ui-react';
import { Form, Input, Dropdown } from '../../../../../components';

const timeZones = listTimeZones();
const timeZonesOptions = timeZones.map(item => ({ key: item, value: item, text: item }));
const schema = Yup.object().shape({
  cnpj: Yup.number().required(),
  name: Yup.string().required(),
  timezone: Yup.string().oneOf(timeZones).required(),
  location: Yup.object().shape({
    address: Yup.object().shape({
      street: Yup.string().required(),
      number: Yup.number().required(),
      neighborhood: Yup.string().required(),
      postalCode: Yup.number().required(),
      city: Yup.string().required(),
      state: Yup.string().required()
    }).required(),
    coordinates: Yup.array().of(Yup.number()).required().min(2).max(2)
  }).required()
});

const GeneralStep = ({ active, completed }) => {
  const { t } = useTranslation();

  return (
    <Step active={active} completed={completed}>
      <Icon name='info' />
      <Step.Content>
        <Step.Title>{t('BUILDINGS.NEW.GENERAL_STEP.TITLE')}</Step.Title>
        <Step.Description>{t('BUILDINGS.NEW.GENERAL_STEP.DESCRIPTION')}</Step.Description>
      </Step.Content>
    </Step>
  );
};

const GeneralContent = ({ active, building, prev, next }) => {
  const { t } = useTranslation();

  return active && (
    <Form initialData={building} onSubmit={next} schema={schema}>
      <Input
        name='name'
        label={t('BUILDINGS.NEW.GENERAL_STEP.NAME.LABEL')}
        placeholder={t('BUILDINGS.NEW.GENERAL_STEP.NAME.PLACEHOLDER')}
      />
      <SemanticForm.Group widths='equal'>
        <Input
          type='number'
          name='cnpj'
          label={t('BUILDINGS.NEW.GENERAL_STEP.CNPJ.LABEL')}
          placeholder={t('BUILDINGS.NEW.GENERAL_STEP.CNPJ.PLACEHOLDER')}
        />
        <Dropdown
          fluid
          selection
          name='timezone'
          label={t('BUILDINGS.NEW.GENERAL_STEP.TIME_ZONE.LABEL')}
          placeholder={t('BUILDINGS.NEW.GENERAL_STEP.TIME_ZONE.PLACEHOLDER')}
          options={timeZonesOptions}
        />
      </SemanticForm.Group>
      <SemanticForm.Group widths='equal'>
        <Input
          name='location.address.street'
          label={t('BUILDINGS.NEW.GENERAL_STEP.STREET.LABEL')}
          placeholder={t('BUILDINGS.NEW.GENERAL_STEP.STREET.PLACEHOLDER')}
        />
        <Input
          type='number'
          name='location.address.number'
          label={t('BUILDINGS.NEW.GENERAL_STEP.NUMBER.LABEL')}
          placeholder={t('BUILDINGS.NEW.GENERAL_STEP.NUMBER.PLACEHOLDER')}
        />
      </SemanticForm.Group>
      <SemanticForm.Group widths='equal'>
        <Input
          name='location.address.neighborhood'
          label={t('BUILDINGS.NEW.GENERAL_STEP.NEIGHBORHOOD.LABEL')}
          placeholder={t('BUILDINGS.NEW.GENERAL_STEP.NEIGHBORHOOD.PLACEHOLDER')}
        />
        <Input
          type='number'
          name='location.address.postalCode'
          label={t('BUILDINGS.NEW.GENERAL_STEP.POSTAL_CODE.LABEL')}
          placeholder={t('BUILDINGS.NEW.GENERAL_STEP.POSTAL_CODE.PLACEHOLDER')}
        />
      </SemanticForm.Group>
      <SemanticForm.Group widths='equal'>
        <Input
          name='location.address.city'
          label={t('BUILDINGS.NEW.GENERAL_STEP.CITY.LABEL')}
          placeholder={t('BUILDINGS.NEW.GENERAL_STEP.CITY.PLACEHOLDER')}
        />
        <Input
          name='location.address.state'
          label={t('BUILDINGS.NEW.GENERAL_STEP.STATE.LABEL')}
          placeholder={t('BUILDINGS.NEW.GENERAL_STEP.STATE.PLACEHOLDER')}
        />
      </SemanticForm.Group>
      <SemanticForm.Group widths='equal'>
        <Input
          type='number'
          step='any'
          name='location.coordinates[0]'
          label={t('BUILDINGS.NEW.GENERAL_STEP.LATITUDE.LABEL')}
          placeholder={t('BUILDINGS.NEW.GENERAL_STEP.LATITUDE.PLACEHOLDER')}
        />
        <Input
          type='number'
          step='any'
          name='location.coordinates[1]'
          label={t('BUILDINGS.NEW.GENERAL_STEP.LONGITUDE.LABEL')}
          placeholder={t('BUILDINGS.NEW.GENERAL_STEP.LONGITUDE.PLACEHOLDER')}
        />
      </SemanticForm.Group>
      <Button primary onClick={prev}>{t('BUILDINGS.NEW.PREV')}</Button>
      <Button primary type='submit'>{t('BUILDINGS.NEW.NEXT')}</Button>
    </Form>
  );
};

export { GeneralStep, GeneralContent };
