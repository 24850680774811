import React from 'react';
import ReactDOM from 'react-dom';

import App from './pages';

import './config/i18n';
import 'semantic-ui-css/semantic.min.css';
import './assets/styles/global.css';
import * as serviceWorker from './service-worker';

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
